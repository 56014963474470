import { useState } from "react";
import { addDays } from "date-fns";
import { useSelector } from "react-redux";

import { formatDateToAPI } from "../../../utils";
import { getVehicleActivityAPI } from "../../../axios";
import { useIsMobile, useReport } from "../../../hooks";
import { ConnectOneTable, ConnectOneTableControls } from "../../../components";

export const VehicleActivityReport = () => {
  const isMobile = useIsMobile();
  const { vehicleStatusData } = useSelector((state: any) => state.vehicles);

  const {
    reportData,
    currentPageIndex,
    totalPages,
    dataToDisplay,
    isLoading,
    headers,
    exportData,
    sortAscending,
    sortedValue,
    setIsLoading,
    setReportData,
    setCurrentPageIndex,
    handleSorting,
  } = useReport({ isMobile, reportType: "vehicleActivity" });

  const vehicleStatusesMap = new Map(
    vehicleStatusData.map((vehicleStatus: { _id: string; name: string }) => [
      vehicleStatus._id,
      vehicleStatus.name,
    ]),
  );

  const [filterValues, setFilterValues] = useState({
    activity: {
      type: "dropdown",
      label: "Activity Reported",
      value: false,
      options: [
        { label: "Activity Reported", value: true },
        { label: "No Activity Reported", value: false },
      ],
    },
    dateRange: {
      type: "dropdown",
      label: "Date Range",
      value: addDays(new Date(), -1),
      // For each date option, get the date from current date and format it
      options: [
        { label: "Last 1 Day", value: addDays(new Date(), -1) },
        { label: "Last 3 Days", value: addDays(new Date(), -3) },
        { label: "Last 7 Days", value: addDays(new Date(), -7) },
        { label: "Last 30 Days", value: addDays(new Date(), -30) },
        { label: "Last 90 Days", value: addDays(new Date(), -90) },
      ],
    },
  });

  const getTableData = async () => {
    setIsLoading(true);
    setCurrentPageIndex(1);
    try {
      const res: any = await getVehicleActivityAPI({
        dateRange: filterValues.dateRange.value,
        activity: filterValues.activity.value,
      });
      setReportData(
        res.map((vehicle: any) => {
          const statusName = vehicleStatusesMap.get(vehicle.status);
          // Transform the date to a user-friendly format matching other data
          const latestTransaction = vehicle?.lastReportedDate
            ? formatDateToAPI(vehicle?.lastReportedDate)
            : null;
          return {
            ...vehicle,
            statusName,
            latestTransaction,
          };
        }),
      );
    } catch (err) {
      setReportData([]);
      console.log(err);
    }
    setIsLoading(false);
  };

  return (
    <>
      <ConnectOneTableControls
        fullData={reportData}
        dataIsLoading={isLoading}
        totalPages={totalPages}
        currentPageIndex={currentPageIndex}
        setCurrentPageIndex={setCurrentPageIndex}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        handleFilterSubmit={getTableData}
        submitButtonDisabled={false}
        fileName={"VEHICLE_ACTIVITY_REPORT"}
        exportData={exportData}
      />
      <ConnectOneTable
        data={dataToDisplay}
        headers={headers}
        caption="Vehicle Activity"
        sortGridData={handleSorting}
        sortAscending={sortAscending}
        sortedValue={sortedValue}
      />
    </>
  );
};
