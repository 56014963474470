/* eslint-disable array-callback-return */
import { useState } from "react";

import { useIsMobile } from "../../../hooks";
import { getCognitoCredentials } from "../../../utils";
import {
  ConnectOneCheckboxForm,
  ConnectOneOverlay,
  ConnectOneButton,
  ConnectOneDropdown,
  ConnectOneInput,
  ConnectOneTextBox,
  ConnectOneCheckbox,
} from "../../../components";
import {
  EventBridgeClient,
  PutEventsCommand,
} from "@aws-sdk/client-eventbridge";

import "./Vehicles.scss";

export const VehiclesEditForm = ({
  data,
  label = "",
  handleChange,
  handleCheckbox,
  handleGoBack,
  handleSubmit,
  isLoading,
  isCreating = false,
  isReadOnly = false,
}: {
  data: any;
  label: string;
  handleChange: any;
  handleCheckbox: any;
  handleGoBack: any;
  handleSubmit: any;
  isLoading: boolean;
  isCreating?: boolean;
  isReadOnly?: boolean;
}) => {
  const isMobile = useIsMobile();

  const [statusChanged, setStatusChanged] = useState(false);
  const [newStatus, setNewStatus] = useState("");

  const confirmSubmit = () => {
    if (statusChanged) {
      const actionNames = {
        "657776a0cdb69be2659ebfef": "activate",
        "657776d1cdb69be2659ebff5": "remove",
        "657776c7cdb69be2659ebff4": "inactivate",
      };

      // @ts-ignore
      const actionName = actionNames[newStatus] || "change";

      if (
        window.confirm(
          `You are about to ${actionName} the service for this vehicle. Do you want to process?`
        )
      )
        handleSubmit();
    } else {
      handleSubmit();
    }
  };

  const saveDisabled = Object.values(data).some((data: any) => {
    return data.required && data.value === "";
  });

  const handleSendMessages = async (
    eventBusName = `VehiclePolicyPush-${process.env.REACT_APP_USER_BRANCH}`,
    source = "VerraMobility.ConnectedVehicle.UI",
    detailType = "InVehicleMessageEvent",
    resources: string[] = []
  ) => {
    const { vin, provider } = data;
    const vehicleVin = vin.value;
    const providerName = provider.value;
    const currentUTC = new Date().toISOString();
    const credentials = await getCognitoCredentials();

    const client = new EventBridgeClient({
      region: process.env.REACT_APP_region,
      credentials: credentials,
    });

    const response = await client.send(
      new PutEventsCommand({
        Entries: [
          {
            Detail: JSON.stringify({
              vin: `${vehicleVin}`,
              provider: `${providerName}`,
              policy: 0,
              requestDateTime: `${currentUTC}`,
              requestType: "CUSTOMMESSAGE",
              dynamicData: { message: "This is a test" },
              category: 1,
            }),
            EventBusName: eventBusName,
            DetailType: detailType,
            Resources: resources,
            Source: source,
          },
        ],
      })
    );

    window.alert(
      "Message sent. The message could take up to 10 seconds to be received."
    );
    return response;
  };

  return (
    <>
      {!isMobile && <ConnectOneOverlay />}

      <div
        className={`edit-vehicle-container ${
          isMobile ? "mobile-edit-vehicle-container" : null
        }`}
        data-testid={`${label}-edit-form`}
      >
        <div className="edit-form-headers">
          {!isMobile && (
            <div
              className="x-container"
              onClick={() => handleGoBack("editing")}
              data-testid="search-modal-close-btn"
            >
              <span aria-hidden="true">&times;</span>
            </div>
          )}
          <h1>{isCreating ? `Create New Vehicle` : `Update Vehicle`}</h1>
          <span />
        </div>

        <div className="edit-forms">
          <div className="edit-form-inputs">
            {Object.entries(data).map(
              ([key, value]: [key: string, value: any]) => {
                // Handle checkboxes in own component

                if (!value.label || value?.type?.includes("checkbox")) return;

                // Status field was pulled out from the rest of the
                // fields  to add logic for a modal so that the user
                // is aware of the change
                if (value.name === "status") {
                  return (
                    <ConnectOneDropdown
                      handleChange={(e: any) => {
                        setStatusChanged(true);
                        handleChange(e);
                        setNewStatus(e.target.value);
                      }}
                      label={value.label}
                      name={value.name}
                      options={value.options}
                      required={value.required}
                      value={value.value}
                      readOnly={isReadOnly || value.readOnly}
                      alwaysEditable={value.alwaysEditable}
                    />
                  );
                }

                if (value.type === "dropdown") {
                  return (
                    <span key={key}>
                      <ConnectOneDropdown
                        handleChange={handleChange}
                        label={value.label}
                        name={value.name}
                        options={value.options}
                        required={value.required}
                        value={value.value}
                        readOnly={isReadOnly || value.readOnly}
                        alwaysEditable={value.alwaysEditable}
                      />
                    </span>
                  );
                }

                if (value.type === "text-area") {
                  return (
                    <span key={key}>
                      <ConnectOneTextBox
                        handleChange={handleChange}
                        label={value.label}
                        type={value.type}
                        name={value.name}
                        placeholder={value.label}
                        value={value.value}
                        required={value.required}
                        readOnly={isReadOnly || value.readOnly}
                      />
                    </span>
                  );
                }
                return (
                  <span key={key}>
                    <ConnectOneInput
                      handleChange={handleChange}
                      label={value.label}
                      type={value.type}
                      name={value.name}
                      placeholder={value.label}
                      value={value.value}
                      required={value.required}
                      readOnly={isReadOnly || value.readOnly}
                    />
                  </span>
                );
              }
            )}
          </div>

          <div className="messages-container">
            <h2>Infotainment Preferences:</h2>
            <span key={"receiveInfotainmentMessages-checkbox"}>
              <ConnectOneCheckbox
                handleChange={handleChange}
                label="Receive Messages"
                name="communicationPreferences.receiveInfotainmentMessages"
                value={
                  data["communicationPreferences"]?.value?.hasOwnProperty(
                    "receiveInfotainmentMessages"
                  )
                    ? data["communicationPreferences"]?.value[
                        "receiveInfotainmentMessages"
                      ]
                    : false
                }
                labelPosition="end"
              />
            </span>
          </div>

          <div className="services-container">
            <h2>Services:</h2>
            {Object.entries(data).map(
              ([key, value]: [key: string, value: any]) => {
                if (value?.type?.includes("checkboxes")) {
                  return (
                    <span key={key}>
                      <ConnectOneCheckboxForm
                        type={value.type}
                        checkboxData={value}
                        handleCheckbox={handleCheckbox}
                        isReadOnly={isReadOnly}
                      />
                    </span>
                  );
                }
              }
            )}
          </div>
          {!isCreating && data.provider.value === "Stellantis" && (
            <div className="test-message-container">
              <h2>Vehicle Test Message:</h2>
              <ConnectOneButton
                label="Send Test Message"
                handleClick={() => handleSendMessages()}
              />
            </div>
          )}
        </div>

        <div className="button-container">
          {isMobile && (
            <div className="close-button">
              <ConnectOneButton
                label="Close"
                handleClick={() =>
                  handleGoBack(`${isCreating ? "results" : "editing"}`)
                }
              />
            </div>
          )}
          <div className="save-button">
            <ConnectOneButton
              label="Save"
              handleClick={confirmSubmit}
              isLoading={isLoading}
              disabled={saveDisabled}
            />
          </div>
        </div>
      </div>
    </>
  );
};
