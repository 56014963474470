/* eslint-disable array-callback-return */
import { Expander, ExpanderItem } from "@aws-amplify/ui-react";

import { useIsMobile } from "../../hooks";

import "./Route.scss";

const ParentRouteTitle = ({
  label,
  icon,
  isMobile,
}: {
  label: string;
  icon: any;
  isMobile: boolean;
}) => {
  return (
    <div
      className={`parent-route-title ${
        isMobile ? "parent-mobile-route" : null
      }`}
    >
      {icon}
      <h1>{label}</h1>
    </div>
  );
};

export const ConnectOneRoute = ({
  onClick,
  route,
  activeRoute,
  isAdmin,
}: {
  onClick: (path: string) => void;
  route: any;
  activeRoute: string;
  isAdmin?: boolean;
}) => {
  const isMobile = useIsMobile();

  return (
    <div
      className={`route ${isMobile ? "mobile-route" : null}`}
      key={`${route.name}-nav-text`}
    >
      {((route?.adminOnly && isAdmin) || !route?.adminOnly) && (
        <>
          {route?.children?.length ? (
            <div className="parent-route">
              <Expander
                type="multiple"
                defaultValue={[
                  `${
                    activeRoute.includes(route.basePath) ? route.basePath : ""
                  }`,
                ]}
              >
                <ExpanderItem
                  title={
                    <ParentRouteTitle
                      label={route.name}
                      icon={<route.icon />}
                      isMobile={isMobile}
                    />
                  }
                  value={route.basePath}
                >
                  {route.children.map((child: any) => {
                    if ((child?.adminOnly && isAdmin) || !child?.adminOnly) {
                      return (
                        <span
                          key={child.path}
                          onClick={() => onClick(child.path)}
                          className={`${
                            activeRoute === child.path ? "active" : null
                          } child-nav-text parent-route-option`}
                        >
                          <p data-testid={`${child.name}-nav-text`}>
                            {child.name}
                          </p>
                        </span>
                      );
                    }
                  })}
                </ExpanderItem>
              </Expander>
            </div>
          ) : (
            <span
              onClick={() => onClick(route.path)}
              className={`${
                activeRoute === route.path ? "active" : null
              } route-option`}
            >
              <route.icon />

              <h1 data-testid={`${route.name}-nav-text`}>{route.name}</h1>
            </span>
          )}
        </>
      )}
    </div>
  );
};
