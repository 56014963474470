import { SelectField, Text } from "@aws-amplify/ui-react";

import { useIsMobile } from "../../../hooks";

import "./Dropdown.scss";

export const ConnectOneDropdown = ({
  handleChange,
  options,
  name,
  label = undefined,
  value = undefined,
  required = false,
  readOnly = false,
  alwaysEditable = false, // Used for cases where checkbox determines rest of readme
  disabled = false,
}: {
  handleChange: any;
  options: any[];
  name: string;
  placeholder?: string;
  label?: string | JSX.Element;
  value?: string;
  required?: boolean;
  readOnly?: boolean;
  alwaysEditable?: boolean;
  disabled?: boolean;
}) => {
  const isMobile = useIsMobile();

  const requiredStatus = required && !value;

  const isDisabled = () => {
    if (disabled) return true;
    if (alwaysEditable) return false;

    return readOnly;
  };

  return (
    <div
      className={`${isMobile ? "mobile-input" : null} input`}
      key={`dropdown-${label}`}
    >
      <SelectField
        label={
          <Text>
            {label}
            {requiredStatus && (
              <Text as="span" color="red">
                {" "}
                *
              </Text>
            )}
          </Text>
        }
        onChange={handleChange}
        name={name}
        value={value !== undefined ? value : "none"}
        isRequired={required}
        disabled={isDisabled()}
        data-testid={`${name}-dropdown`}
      >
        {!value && (
          <option value={"none"} key={"none-option"} disabled hidden></option>
        )}
        {options?.length && (
          <>
            {options?.map((option) => {
              return (
                <option
                  value={option.value}
                  key={option.value}
                  data-testid={`${label}-${option.label}-option`}
                >
                  {option.label}
                </option>
              );
            })}
          </>
        )}
      </SelectField>
    </div>
  );
};
