import {
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
} from "@aws-amplify/ui-react";

import { ArrowIcon } from "../../../assets";

import "./Table.scss";

export const ConnectOneTable = ({
  headers,
  data,
  caption = "",
  isMatching = false,
  sortGridData = undefined,
  sortAscending,
  sortedValue,
}: {
  headers: any[];
  data: any[];
  caption?: string;
  isMatching?: boolean;
  sortGridData?: any;
  sortAscending?: boolean;
  sortedValue?: string;
}) => {
  return (
    <div data-testid="table-container" className="table-container">
      {!!data?.length && (
        <div
          className={`${!!data.length ? "table" : null} ${
            isMatching ? "matching-tables" : null
          }`}>
          <Table variation="striped" caption={caption}>
            <TableHead>
              <TableRow>
                {headers.map((header) => {
                  return (
                    <TableCell
                      as="th"
                      onClick={() => sortGridData(header.value)}
                      key={header.name}
                      data-testid={`${header.value}-header`}
                    >
                      <div className="cell-header">
                        <p>{header.name}</p>
                        {sortedValue === header.value && (
                          <span
                            className={
                              sortAscending
                                ? "arrow-icon-ascending"
                                : "arrow-icon-descending"
                            }
                          >
                            <ArrowIcon />
                          </span>
                        )}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => {
                return (
                  <TableRow key={`row-${index}`}>
                    {headers.map((header: any) => {
                      let cellValue: any = "";

                      if (
                        header.parentValue &&
                        row.hasOwnProperty(header.parentValue) &&
                        row[header.parentValue].hasOwnProperty(header.value)
                      ) {
                        cellValue = `${row[header.parentValue][header.value]}`;
                      } else if (
                        row.hasOwnProperty(header.value) &&
                        row[header.value] !== null
                      ) {
                        cellValue = `${row[header.value]}`;
                      }

                      if (typeof cellValue === "object") {
                        cellValue = cellValue.label;
                      }

                      if (typeof cellValue === "boolean") {
                        cellValue = cellValue ? "True" : "False";
                      }

                      // Handle empty cells
                      if (cellValue === "") {
                        cellValue = "N/A";
                      }

                      return (
                        <TableCell
                          key={`row-${index}-${header.name}`}
                          data-testid={`${cellValue}-cell-data`}>
                          {cellValue}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
};
