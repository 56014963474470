import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { addDays, addHours, format } from "date-fns";

import { Transaction } from "../../../types";
import { formatDateToAPI } from "../../../utils";
import { useIsMobile, useReport } from "../../../hooks";
import { getTransactionsAPI, getTransactionTypesAPI } from "../../../axios";
import { ConnectOneTable, ConnectOneTableControls } from "../../../components";

export const TransactionReport = () => {
  const isMobile = useIsMobile();

  const { agenciesData } = useSelector((state: any) => state.agencies);
  const { classTypesData } = useSelector((state: any) => state.classTypes);
  const { rawGeofenceData } = useSelector((state: any) => state.geofences);

  const {
    reportData,
    currentPageIndex,
    totalPages,
    dataToDisplay,
    isLoading,
    headers,
    exportData,
    sortAscending,
    sortedValue,
    exportFileName,
    setIsLoading,
    setReportData,
    setCurrentPageIndex,
    userFriendlyDateInsert,
    handleSorting,
  } = useReport({ isMobile, reportType: "transaction" });

  const agencyMap = new Map(
    agenciesData.map((agency: { _id: string; name: string }) => [
      agency._id,
      agency.name,
    ]),
  );
  const classTypeMap = new Map(
    classTypesData.map((classType: { _id: string; name: string }) => [
      classType._id,
      classType.name,
    ]),
  );
  const locationsMap = new Map(
    rawGeofenceData?.features.map((geofence: { _id: string; name: string }) => [
      geofence._id,
      geofence.name,
    ]),
  );

  const [filterValues, setFilterValues] = useState({
    vin: {
      type: "search",
      label: "Vin",
      value: "",
    },
    status: {
      type: "dropdown",
      label: "Status",
      value: "",
      options: [] as any,
    },
    dateFrom: {
      type: "datetime-local",
      label: "Date From",
      value: `${format(addDays(new Date(), -7), "yyyy-MM-dd")}T00:00`,
    },
    dateTo: {
      type: "datetime-local",
      label: "Date To",
      value: `${format(new Date(), "yyyy-MM-dd")}T${format(
        addHours(new Date(), 1).getTime(),
        "HH:",
      )}00`,
    },
  });

  useEffect(() => {
    getTransactionTypesAPI().then((data) => {
      const transactionTypes = data.map((type: any) => ({
        label: type.name,
        value: type.value,
      }));
      setFilterValues((prevFilterValues: any) => ({
        ...prevFilterValues,
        status: {
          ...prevFilterValues.status,
          options: [{ label: "", value: "" }, ...transactionTypes],
        },
      }));
    });
  }, []);

  const submitButtonEnabled = Object.values(filterValues).some(
    (filterValue: any) => !!filterValue.value,
  );

  const getTableData = async () => {
    setIsLoading(true);
    setCurrentPageIndex(1);
    try {
      const res: any = await getTransactionsAPI({
        vin: filterValues.vin.value,
        dateFrom: formatDateToAPI(filterValues.dateFrom.value),
        dateTo: formatDateToAPI(filterValues.dateTo.value),
        status: filterValues.status.value,
      });
      const data = userFriendlyDateInsert({
        data: res,
        localDate: "tollDateTimeLocal",
        utcDate: "tollDateTime",
      });
      setReportData(
        data.map((transaction: any) => {
          const agencyName = agencyMap.get(transaction.agencyId);
          const classTypeName = classTypeMap.get(transaction.vehicleClassId);
          let plazaName: string;

          // If the entry and exit locations are the same, only display the entry location
          // Otherwise, display both the entry and exit locations
          if (transaction.entryLocationId === transaction.exitLocationId) {
            plazaName = `${locationsMap.get(transaction.entryLocationId)}`;
          } else {
            plazaName = `Entry: ${locationsMap.get(
              transaction.entryLocationId,
            )}\nExit: ${locationsMap.get(transaction.exitLocationId)}`;
          }

          return {
            ...transaction,
            plazaName: plazaName ? plazaName : "",
            agencyName: agencyName,
            className: classTypeName,
          };
        }),
      );
    } catch (err) {
      setReportData([] as Transaction[]);
      console.log(err);
    }
    setIsLoading(false);
  };

  return (
    <>
      <ConnectOneTableControls
        fullData={reportData}
        dataIsLoading={isLoading}
        totalPages={totalPages}
        currentPageIndex={currentPageIndex}
        setCurrentPageIndex={setCurrentPageIndex}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        handleFilterSubmit={getTableData}
        submitButtonDisabled={!submitButtonEnabled}
        fileName={exportFileName("TRANSACTION_REPORT", filterValues)}
        exportData={exportData}
      />
      <ConnectOneTable
        data={dataToDisplay}
        headers={headers}
        caption="Transactions"
        sortGridData={handleSorting}
        sortAscending={sortAscending}
        sortedValue={sortedValue}
      />
    </>
  );
};
