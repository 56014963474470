// @ts-nocheck
import { useCallback, useEffect, useState } from "react";
import { useControl } from "react-map-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as MapboxDrawGeodesic from "mapbox-gl-draw-geodesic";

import {
  RotateButton,
  RotateScaleMode,
  GeneratePolyMode,
  ScaleButton,
  CircleButton,
} from "./customControls";
import { DrawControlConfig } from "./DrawControl.config";
import { DrawControlsStyles } from "./DrawControlsStyles";

import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

let modes = MapboxDraw.modes;
modes = {
  ...MapboxDrawGeodesic.enable(modes),
  RotateScaleMode: RotateScaleMode,
  GeneratePolyMode: GeneratePolyMode,
};
const draw = new MapboxDraw({
  ...DrawControlConfig,
  modes,
});

export const DrawControl = ({
  onUpdate,
  onSelect,
  onGenerate,
  onMeasure,
  onMeasureEnd,
  onGeofenceDelete,
  clearRulerLine,
  geofences,
  userIsReadOnly,
  resetView,
  setResetView,
  dynamicFillColorsByType,
  dynamicFillColorsByStatus,
}: {
  onUpdate: any;
  onSelect: any;
  onGenerate: any;
  onMeasure: any;
  onMeasureEnd: any;
  onGeofenceDelete: any;
  clearRulerLine: any;
  geofences: any;
  userIsReadOnly: boolean;
  resetView: boolean;
  setResetView: any;
  dynamicFillColorsByType: any[];
  dynamicFillColorsByStatus: any[];
}): any => {
  const [mapLoaded, setMapLoaded] = useState(false);
  const [lineId, setLineId] = useState(null);
  const [linePoints, setLinePoints] = useState([]);
  const [currentDrawMode, setCurrentDrawMode] = useState("simple_select");

  draw.options.controls = DrawControlConfig.controls;

  // Set the dynamic colors for the geofences
  useEffect(() => {
    if (dynamicFillColorsByType.length && dynamicFillColorsByStatus.length) {
      draw.options.styles = DrawControlsStyles.concat([
        {
          id: "gl-draw-polygon-fill-inactive.cold",
          type: "fill",
          filter: [
            "all",
            ["==", "$type", "Polygon"],
            ["==", "active", "false"],
          ],
          paint: {
            "fill-color": [
              "match",
              ["get", "user_status"], // Check status of the geofence first, if it's inactive or removed use grey color
              ...dynamicFillColorsByStatus,
              [
                "match",
                ["get", "user_locationType"],
                ...dynamicFillColorsByType,
                "#3956ff", // default
              ],
            ],
            "fill-opacity": 0.4,
          },
          source: "mapbox-gl-draw-cold",
        },
        {
          id: "gl-draw-polygon-fill-inactive.hot",
          type: "fill",
          filter: [
            "all",
            ["==", "$type", "Polygon"],
            ["==", "active", "false"],
          ],
          paint: {
            "fill-color": [
              "match",
              ["get", "user_status"], // Check status of the geofence first, if it's inactive or removed use grey color
              ...dynamicFillColorsByStatus,
              [
                "match",
                ["get", "user_locationType"],
                ...dynamicFillColorsByType,
                "#3956ff", // default
              ],
            ],
            "fill-opacity": 0.4,
          },
          source: "mapbox-gl-draw-hot",
        },
      ]);
    }
  }, [dynamicFillColorsByType, dynamicFillColorsByStatus]);

  useEffect(() => {
    if (mapLoaded) {
      if ((geofences?.features && geofences.features.length) || resetView) {
        draw.deleteAll();
        draw.add(geofences);
        setResetView(false);
        draw.changeMode("simple_select");
        const tempGeofence = geofences.features.find(
          (fence: any) => fence.isTemp
        );
        if (tempGeofence) {
          // Set the tempGeofence as the selected feature in draw
          draw.changeMode("direct_select", {
            featureId: tempGeofence.id,
          });
        }
      } else {
        draw.deleteAll();
      }
    }
  }, [mapLoaded, geofences, userIsReadOnly, resetView, setResetView]);

  // Stop user from drawing more than 2 points for ruler use
  useEffect(() => {
    if (linePoints.length === 2) {
      draw.changeMode("simple_select");
    }
  }, [lineId, linePoints]);

  // Clear the ruler line when the user is done
  useEffect(() => {
    if (clearRulerLine) {
      draw.delete(lineId);
    }
  }, [clearRulerLine, lineId]);

  useEffect(() => {
    if (currentDrawMode !== "draw_line_string" && linePoints.length === 1) {
      onMeasureEnd();
      setLinePoints([]);
    }
    if (linePoints.length > 1) {
      setLinePoints([]);
    }
  }, [currentDrawMode, linePoints.length, onMeasureEnd]);

  // Create or update of a feature
  const updateArea = useCallback(
    (e: any) => {
      if (e.features[0].geometry.type === "Polygon") {
        onUpdate(e);
      }

      if (e.features[0].geometry.type === "LineString") {
        onMeasure({
          startPoint: e.features[0].geometry.coordinates[0],
          endPoint: e.features[0].geometry.coordinates[1],
        });
      }
    },
    [onMeasure, onUpdate]
  );

  // Selection change
  const selectionChange = useCallback(
    (e: any) => {
      if (e?.features[0]?.geometry?.type === "Polygon") {
        onSelect(e);
      }
    },
    [onSelect]
  );

  // Generate a polygon
  const generatePolygon = useCallback(
    (lon: number, lat: number) => onGenerate(lon, lat),
    [onGenerate]
  );

  useControl(({ map }) => {
    map.on("load", () => {
      setMapLoaded(true);
      if (!userIsReadOnly) {
        // Add our custom controls
        const rotateButton = new RotateButton(draw);
        const scaleButton = new ScaleButton(draw);
        const circleButton = new CircleButton(draw);

        map.addControl(rotateButton);
        map.addControl(scaleButton);
        map.addControl(circleButton);

        // Set title for user to hover and see what the tool does
        document
          .querySelector(".mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_polygon")
          .setAttribute("title", "Polygon Tool");
        document
          .querySelector(".mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_point")
          .setAttribute("title", "Generate Polygon Tool");
        document
          .querySelector(".mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_trash")
          .setAttribute("title", "Delete Tool");
        document
          .querySelector(".mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_line")
          .setAttribute("title", "Measure Tool");

        document.getElementsByClassName("custom-ctrl-rotate")[0].style.display =
          "none";
        document.getElementsByClassName("custom-ctrl-scale")[0].style.display =
          "none";
      }
    });
    map.on("draw.create", updateArea);
    map.on("draw.update", updateArea);
    map.on("draw.selectionchange", selectionChange);
    map.on("click", (e) => {
      // Click custom draw controls
      if (draw.getMode() === "GeneratePolyMode") {
        generatePolygon(parseFloat(e.lngLat.lng), parseFloat(e.lngLat.lat));
        draw.changeMode("simple_select");
      }

      if (draw.getMode() === "draw_line_string") {
        setLinePoints((prevLinePoints: any) => [
          ...prevLinePoints,
          [{ lon: parseFloat(e.lngLat.lng), lat: parseFloat(e.lngLat.lat) }],
        ]);
      }
    });
    map.on("draw.modechange", (e) => {
      setCurrentDrawMode(draw.getMode());

      if (draw.getMode() === "draw_point") {
        draw.changeMode("GeneratePolyMode");
        map.getCanvas().style.cursor = "crosshair";
      }
    });

    // Customizing the draw controls
    if (userIsReadOnly) {
      // Hide draw controls from read only user
      draw.options.controls = {};
      // Hide drag controls from read only user
      modes.simple_select.clickOnFeature = (state, e) =>
        onSelect({
          features: [
            {
              properties: {
                ...e.featureTarget.properties,
                agencyName: e.featureTarget.properties.user_agencyName,
                agencyId: e.featureTarget.properties.user_agencyId,
                locationType: e.featureTarget.properties.user_locationType,
                circleCenter: e.featureTarget.properties.user_circleCenter,
                name: e.featureTarget.properties.user_name,
                schedules: JSON.parse(
                  e.featureTarget.properties.user_schedules
                ),
                eventActions: JSON.parse(
                  e.featureTarget.properties.user_eventActions
                ),
                notes: e.featureTarget.properties.user_notes,
                status: e.featureTarget.properties.user_status,
              },
              geometry: e.featureTarget._geometry,
            },
          ],
        });
    } else {
      modes.simple_select.clickOnFeature = (state, e) => {
        // If the feature is locked, we want to select it and not allow the user to edit the shape
        // NEED TO MAKE SURE TO UPDATE THIS TO USE THE USER PROPERTIES
        if (e.featureTarget.properties.user_lockStatus) {
          return onSelect({
            features: [
              {
                ...e.featureTarget,
                properties: {
                  ...e.featureTarget.properties,
                  agencyName: e.featureTarget.properties.user_agencyName,
                  agencyId: e.featureTarget.properties.user_agencyId,
                  locationType: e.featureTarget.properties.user_locationType,
                  circleCenter: e.featureTarget.properties.user_circleCenter,
                  name: e.featureTarget.properties.user_name,
                  schedules: JSON.parse(
                    e.featureTarget.properties.user_schedules
                  ),
                  eventActions: JSON.parse(
                    e.featureTarget.properties.user_eventActions
                  ),
                  lockStatus: e.featureTarget.properties.user_lockStatus,
                  notes: e.featureTarget.properties.user_notes,
                  locationSubType:
                    e.featureTarget.properties.user_locationSubType,
                  status: e.featureTarget.properties.user_status,
                  verified: e.featureTarget.properties.user_verified,
                },
                geometry: e.featureTarget._geometry,
              },
            ],
          });
        } else {
          return draw.changeMode("direct_select", {
            featureId: e.featureTarget.properties.id,
          });
        }
      };
    }

    modes.draw_line_string.onMouseMove = (state, e) => {
      if (draw.getMode() === "draw_line_string") {
        // Keep old functionality
        state.line.updateCoordinate(
          state.currentVertexPosition,
          e.lngLat.lng,
          e.lngLat.lat
        );

        // Added functionality
        // If we already set the start point, we want to start tracking the users mouse movement
        if (state.currentVertexPosition === 1) {
          setLineId(state.line.id);
          onMeasure({
            startPoint: state.line.getCoordinate(0),
            endPoint: [parseFloat(e.lngLat.lng), parseFloat(e.lngLat.lat)],
          });
        }
      }
    };

    // Custom trash functionality
    modes.direct_select.onTrash = (state) => {
      // If the polygon is only 3 points, we want to delete the entire feature
      if (state.feature.coordinates[0].length === 3) {
        onGeofenceDelete(
          { features: [state.feature] },
          `Removal of third point will delete this geofence. Confirm deletion of geofence: ${state.feature.properties.name}?`
        );
        return;
      }

      // Keep old functionality
      state.selectedCoordPaths
        .sort((a, b) => b.localeCompare(a, "en", { numeric: true }))
        .forEach((id) => state.feature.removeCoordinate(id));
      state.selectedCoordPaths = [];

      // Need to update the feature if user needs to remove a point
      onUpdate({
        features: [
          {
            ...state.feature,
            type: "Feature",

            geometry: {
              coordinates: [
                [
                  ...state.feature.coordinates[0],
                  state.feature.coordinates[0][0],
                ],
              ],
              type: "Polygon",
            },
          },
        ],
      });

      if (state.feature.isValid() === false) {
        draw.deleteFeature([state.featureId]);
      }
    };

    return draw;
  });

  return null;
};
