/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const tracesByVin = /* GraphQL */ `
  query TracesByVin($vin: String!, $dateFrom: Float!, $dateTo: Float!) {
    tracesByVin(vin: $vin, dateFrom: $dateFrom, dateTo: $dateTo) {
      _id
      vin
      feature
      data {
        timestamp
        tollingSpeed
        passengerPresent
        speedometer
        powerMode
        latitude
        odometer
        longitude
        trailerName
        chassisType
        course
        trailerType
        deviceName
        altitude
        timeStampUTC
        timeZone
        horizontalAccuracy
        vehicleState
        __typename
      }
      provider
      dataSource
      __typename
    }
  }
`;
