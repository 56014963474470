import { useMemo, useRef } from "react";
import { isEmpty } from "lodash";
import { Marker } from "react-map-gl";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { MapView } from "@aws-amplify/ui-react-geo";

import {
  ConnectOneFilterBar,
  ConnectOneMapMarker,
  ConnectOneToggle,
  DrawControl,
  GeofenceModal,
  ConnectOneSwitchButtons,
  ConnectOneLookupModal,
  ConnectOneLocationSearch,
  ConnectOneLoader,
  ConnectOneGeneratePolygonModal,
  ConnectOneFlyout,
  ConnectOneMeasurementModal,
} from "../../components";
import { useGeofence, useIsMobile, useMap } from "../../hooks";

import "./Map.scss";
import "@aws-amplify/ui-react-geo/styles.css";
import "maplibre-gl-js-amplify/dist/public/amplify-ctrl-geofence.css";

export const MapPage = ({ user }: { user?: any }) => {
  const map = useRef(null);
  const isMobile = useIsMobile();
  const userIsReadOnly = user.attributes["custom:userType"] === "readonly";
  const userIsAdmin = user.attributes["custom:userType"] === "admin";

  // Get the locationId from the query params
  const [searchParams] = useSearchParams();
  const locationId = searchParams.get("locationId") || null;

  // Redux state
  const { initialLoad, geofencesData, rawGeofenceData, isLoading } =
    useSelector((state: any) => state.geofences);
  const { agenciesData, isLoading: agenciesLoading } = useSelector(
    (state: any) => state.agencies
  );
  const { locationHierarchyData, isLoading: locationsLoading } = useSelector(
    (state: any) => state.locationHierarchy
  );

  // Check if the mapbox-draw config is ready
  const configReady = !agenciesLoading && !locationsLoading;

  const {
    mapStyle,
    isDataLoading,
    filterValues,
    tracePoints,
    submitButtonDisabled,
    showControls,
    showFilters,
    showLookupModal,
    showRulerModal,
    dynamicFillColorsByType,
    dynamicFillColorsByStatus,
    measurementData,
    clearRulerLine,
    setShowLookupModal,
    getDataFromAppSync,
    useInterval,
    handleFilterSubmit,
    handleFilterChanges,
    handleMapStyleSwitch,
    handleOpenMobileMenu,
    handleMapMeasure,
    handleMapMeasureEnd,
  } = useMap({
    map,
    agencies: agenciesData,
    locationData: locationHierarchyData,
  });

  const {
    mapView,
    modalData,
    displayModal,
    isSaving,
    geofenceShapeData,
    realTimeDataToggle,
    geofenceToggles,
    generatePolygonData,
    isEditingGeofence,
    resetView,
    queryParamsGeofence,
    setResetView,
    setRealTimeDataToggle,
    setGeofenceToggles,
    handleGeofenceModalBack,
    handleRemoveScheduleOrRate,
    handleGeofenceUpdate,
    handleGeofenceDelete,
    handleGeofenceSelection,
    handleGeofenceModalClose,
    handleGeofenceUndo,
    handleGeofenceSave,
    handleGeofencePropertyChange,
    handleScheduleOrRateChange,
    handleInsertScheduleOrRate,
    handleGeneratePolygonDisplayModal,
    handleChangeGeneratePolygon,
    handleGeneratePolygon,
    handleCloseGeneratePolygonModal,
    handleScheduleSave,
    handleUndoScheduleChanges,
    handleLockGeofence,
    handleGeofenceClone,
    handleGetAllEntryPoints,
    handleRemoveExclusiveDate,
  } = useGeofence({
    rawGeofenceData,
    queryParamsLocation: locationId,
  });

  // Set interval to poll vehicle trace data
  useInterval(
    getDataFromAppSync,
    realTimeDataToggle.value ? 3 * 1000 : 60 * 1000
  );

  // Reduces the times the TraceMarkers are re-rendered when user is moving map
  const TraceMarkersMemo = useMemo(() => {
    return (
      <>
        {tracePoints.map((tracePoint) => (
          <ConnectOneMapMarker key={tracePoint._id} tracePoint={tracePoint} />
        ))}
      </>
    );
  }, [tracePoints]);

  return (
    <div
      className={`${
        isMobile ? "mobile-map-container" : "desktop-map-container"
      } map-container ${isEditingGeofence ? "editing-map-view" : null}`}
      data-testid="map-page"
    >
      {initialLoad && <ConnectOneLoader />}

      <ConnectOneFilterBar
        handleChange={handleFilterChanges}
        filterValues={filterValues}
        handleSubmit={handleFilterSubmit}
        dataIsLoading={isDataLoading}
        submitButtonDisabled={submitButtonDisabled}
        showFilters={showFilters}
        handleOpenMobileMenu={handleOpenMobileMenu}
        handleSearch={() => setShowLookupModal(true)}
      />

      {showLookupModal && (
        <ConnectOneLookupModal
          label="VIN"
          lookupType="vin"
          handleClose={() => setShowLookupModal(false)}
          handleSetValue={(data: any) => {
            handleFilterChanges(data);
            setShowLookupModal(false);
          }}
        />
      )}

      {showRulerModal && (
        <ConnectOneMeasurementModal
          data={measurementData}
          handleClose={handleMapMeasureEnd}
        />
      )}

      {generatePolygonData.displayModal && (
        <ConnectOneGeneratePolygonModal
          data={generatePolygonData}
          handleChange={handleChangeGeneratePolygon}
          handleSubmit={handleGeneratePolygon}
          handleClose={handleCloseGeneratePolygonModal}
        />
      )}

      {configReady && (
        <MapView
          ref={map}
          attributionControl={false}
          initialViewState={mapView}
          dragRotate={false}
          mapStyle={mapStyle.style}
        >
          <ConnectOneLocationSearch
            geofences={rawGeofenceData.features}
            isMobile={isMobile}
          />

          <DrawControl
            onUpdate={handleGeofenceUpdate}
            onSelect={handleGeofenceSelection}
            onGenerate={handleGeneratePolygonDisplayModal}
            onMeasure={handleMapMeasure}
            onMeasureEnd={handleMapMeasureEnd}
            onGeofenceDelete={handleGeofenceDelete}
            clearRulerLine={clearRulerLine}
            geofences={geofencesData}
            userIsReadOnly={userIsReadOnly}
            resetView={resetView}
            setResetView={setResetView}
            dynamicFillColorsByType={dynamicFillColorsByType}
            dynamicFillColorsByStatus={dynamicFillColorsByStatus}
          />

          <>{TraceMarkersMemo}</>

          {!!queryParamsGeofence && (
            <Marker
              latitude={mapView.latitude}
              longitude={mapView.longitude}
              color={"red"}
            />
          )}

          <ConnectOneFlyout displayFlyout={displayModal}>
            <GeofenceModal
              geofence={modalData}
              isLoading={isLoading}
              userIsReadOnly={userIsReadOnly}
              userIsAdmin={userIsAdmin}
              isSavingGeofence={isSaving}
              onClose={handleGeofenceModalClose}
              onEditViewClose={handleGeofenceModalBack}
              changesMade={!isEmpty(geofenceShapeData)}
              handlePropertyChange={handleGeofencePropertyChange}
              handleScheduleOrRateChange={handleScheduleOrRateChange}
              handleInsertScheduleOrRate={handleInsertScheduleOrRate}
              handleRemoveScheduleOrRate={handleRemoveScheduleOrRate}
              handleScheduleSave={handleScheduleSave}
              handleUndoScheduleChanges={handleUndoScheduleChanges}
              handleUndo={handleGeofenceUndo}
              handleDelete={handleGeofenceDelete}
              handleSubmit={handleGeofenceSave}
              handleLockGeofence={handleLockGeofence}
              handleGeofenceClone={handleGeofenceClone}
              handleGetAllEntryPoints={handleGetAllEntryPoints}
              handleRemoveExclusiveDate={handleRemoveExclusiveDate}
            />
          </ConnectOneFlyout>
        </MapView>
      )}

      {(isMobile || !showControls) && (
        <div
          className={
            isMobile
              ? "mobile-map-controls-container"
              : "map-controls-container"
          }
          onClick={() => handleOpenMobileMenu("controls")}
        >
          <p>Map Options</p>
        </div>
      )}

      {showControls && (
        <div className="toggle-container" data-testid="toggle-container">
          <div className={isMobile ? "mobile-map-controls" : null}>
            {!isMobile && (
              <div
                className="x-container"
                onClick={() => handleOpenMobileMenu("controls")}
                data-testid="search-modal-close-btn"
              >
                <span aria-hidden="true">&times;</span>
              </div>
            )}
            <div className="toggles">
              <p>Vehicle Markers:</p>
              <ConnectOneToggle
                checked={realTimeDataToggle.value}
                disabled={false}
                label={realTimeDataToggle.label}
                handleChange={(e: any) => {
                  setRealTimeDataToggle({
                    ...realTimeDataToggle,
                    value: e,
                  });
                }}
              />

              <p>Location Types:</p>
              {Object.entries(geofenceToggles).map(
                ([key, value]: [key: string, value: any]) => {
                  return (
                    <span key={value.label}>
                      <ConnectOneToggle
                        checked={value.value}
                        disabled={false}
                        label={value.label}
                        handleChange={(e: any) => {
                          setGeofenceToggles({
                            ...geofenceToggles,
                            [key]: {
                              ...geofenceToggles[key],
                              value: e,
                            },
                          });
                        }}
                      />
                    </span>
                  );
                }
              )}
            </div>

            <ConnectOneSwitchButtons
              header="Map Style"
              values={["Basic", "Hybrid", "Satellite"]}
              active={mapStyle.name}
              handleClick={(e: any) => {
                handleMapStyleSwitch(e.target.value);
              }}
            />
          </div>
          {isMobile && (
            <p
              onClick={() => handleOpenMobileMenu("controls")}
              className="mobile-close-button"
            >
              CLOSE
            </p>
          )}
        </div>
      )}
    </div>
  );
};
