import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useIsMobile } from "../../../hooks";
import { AppDispatch } from "../../../store";
import { getVehiclesByFilter } from "../../../redux";
import { ConnectOneClickableTable, ConnectOneSearchForm } from "../..";
import { vinLookupFilterState, VinLookupHeaders } from "../../../assets";

import "./LookupModal.scss";

export const ConnectOneLookupModal = ({
  label,
  lookupType, // lookuptype should match value we are searching
  handleClose,
  handleSetValue,
}: {
  label: string;
  lookupType: string;
  handleClose: any;
  handleSetValue: any;
}) => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch<AppDispatch>();

  const { vehiclesData: vehicles, vehicleStatusData: vehicleStatuses } =
    useSelector((state: any) => state.vehicles);
  const fuelTypes = useSelector((state: any) => state.fuelTypes.fuelTypesData);
  const classTypes = useSelector(
    (state: any) => state.classTypes.classTypesData,
  );

  if (classTypes?.length) {
    vinLookupFilterState.class.options = [
      { label: "", value: "" },
      ...classTypes,
    ];
  }
  if (fuelTypes?.length) {
    vinLookupFilterState.fuelType.options = [
      { label: "", value: "" },
      ...fuelTypes,
    ];
  }
  if (vehicleStatuses?.length) {
    vinLookupFilterState.status.options = [
      { label: "", value: "" },
      ...vehicleStatuses,
    ];
  }

  const lookupTyping: any = {
    vin: {
      filterData: vinLookupFilterState,
      tableHeaders: VinLookupHeaders,
      apiQuery: getVehiclesByFilter,
    },
  };
  const [searchData, setSearchData] = useState(
    lookupTyping[lookupType].filterData,
  );
  const [responseData, setResponseData] = useState([]);
  const [isSearching, setIsSearching] = useState(true);
  const [isViewing, setIsViewing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleGoBack = () => {
    setIsViewing(false);
    setResponseData([]);
    setSearchData(lookupTyping[lookupType].filterData);
    setIsSearching(true);
  };

  const sortGridData = ({
    sortValue,
    ascending,
  }: {
    sortValue: string;
    ascending: boolean;
  }) => {
    setResponseData(
      [...responseData].sort((a: any, b: any) => {
        if (typeof a[sortValue] === "string")
          return ascending
            ? a[sortValue]
                ?.toLowerCase()
                ?.localeCompare(b[sortValue]?.toLowerCase())
            : b[sortValue]
                ?.toLowerCase()
                ?.localeCompare(a[sortValue]?.toLowerCase());

        if (typeof a[sortValue] === "number")
          return ascending
            ? a[sortValue] - b[sortValue]
            : b[sortValue] - a[sortValue];

        return ascending
          ? a[sortValue] - b[sortValue]
          : b[sortValue] - a[sortValue];
      }),
    );
  };

  useEffect(() => {
    if (vehicles?.length) {
      setResponseData(vehicles);
    }
  }, [vehicles]);

  const getSearchResponse = async () => {
    setIsLoading(true);
    try {
      let params: any = {};
      Object.entries(searchData).forEach(
        ([key, value]: [key: string, value: any]) => {
          if (value.value) {
            params[key] = value.value;
          }
        },
      );
      await dispatch(lookupTyping[lookupType].apiQuery(params));
      setIsSearching(false);
      setIsViewing(true);
    } catch (err) {
      console.log("Get Lookup Modal Search Response Error: ", err);
    }
    setIsLoading(false);
  };

  const handleFilterInputChange = (e: any) => {
    const { name, value } = e.target;

    setSearchData((prevSearchData: any) => {
      return {
        ...prevSearchData,
        // @ts-ignore
        [name]: { ...searchData[name], value: value },
      };
    });
  };

  return (
    <div
      className={`${
        isMobile ? "mobile-lookup-modal-container" : null
      } lookup-modal-container`}
      data-testid={`${label}-lookup-modal`}
    >
      <span className="co-modal-backdrop" />
      <div className="lookup-modal">
        {isSearching && (
          <ConnectOneSearchForm
            label={label}
            searchData={searchData}
            handleChange={handleFilterInputChange}
            handleSearch={getSearchResponse}
            isLoading={isLoading}
            handleClose={handleClose}
          />
        )}

        {isViewing && (
          <ConnectOneClickableTable
            data={responseData}
            label={label}
            headers={lookupTyping[lookupType].tableHeaders}
            sortGridData={sortGridData}
            handleGoBack={handleGoBack}
            handleRowSelection={(row: any) => {
              handleSetValue({ name: lookupType, value: row[lookupType] });
            }}
          />
        )}
      </div>
    </div>
  );
};
