import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useAdmin } from "../../../hooks";
import { AppDispatch } from "../../../store";
import { shapeCSVData } from "../../../utils";
import { VehiclesEditForm } from "./VehiclesEditForm";
import {
  getAllVehicles,
  getVehiclesByFilter,
  putVehicle,
  insertClassNames,
  insertStatusNames,
} from "../../../redux";
import {
  FileTypes,
  vehicleHeaders,
  vehicleEditState,
  vehicleFilterState,
} from "../../../assets";
import {
  ConnectOneSearchForm,
  ConnectOneClickableTable,
  ConnectOneLoader,
} from "../../../components";

import "./Vehicles.scss";

export const AdminControlVehicles = ({ user }: { user?: any }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [exportData, setExportData] = useState({
    cellHeaders: null,
    cellData: null,
  });

  const {
    vehiclesData: vehicles,
    isLoading: vehiclesLoading,
    statusesLoading,
    vehicleStatusData: vehicleStatuses,
    initialLoad,
  } = useSelector((state: any) => state.vehicles);
  const serviceTypes = useSelector((state: any) => state.services.servicesData);
  const fuelTypes = useSelector((state: any) => state.fuelTypes.fuelTypesData);
  const { classTypesData: classTypes, isLoading: classesLoading } = useSelector(
    (state: any) => state.classTypes
  );

  useEffect(() => {
    if (classTypes?.length) {
      vehicleFilterState.class.options = [
        { label: "", value: "" },
        ...classTypes,
      ];
    }
    if (fuelTypes?.length) {
      vehicleFilterState.fuelType.options = [
        { label: "", value: "" },
        ...fuelTypes,
      ];
    }
    if (vehicleStatuses?.length) {
      vehicleFilterState.status.options = [
        { label: "", value: "" },
        ...vehicleStatuses,
      ];
    }
    if (serviceTypes?.length) {
      // @ts-ignore
      vehicleEditState.subscriptions = {
        label: "Services",
        name: "subscriptions",
        type: "checkboxes-object",
        value: [] as string[],
        options: serviceTypes.map((serviceType: any) => {
          return {
            label: serviceType.name,
            name: serviceType.name,
          };
        }),
      };
    }
  }, [classTypes, fuelTypes, serviceTypes, vehicleStatuses]);

  const {
    isSearching,
    isEditing,
    isLoading,
    searchData,
    responseData,
    editData,
    isViewing,
    isCreating,
    displayingFilteredData,
    sortedValue,
    sortAscending,
    handleAdd,
    setIsViewing,
    handleGoBack,
    handleFilterInputChange,
    handleEditInputChange,
    setIsLoading,
    setResponseData,
    setIsSearching,
    handleRowSelection,
    handleEditFormSubmit,
    handleCheckboxChange,
    handleFilterCheckboxChange,
    handleResetSearch,
    handleSorting,
  } = useAdmin({
    filterState: vehicleFilterState,
    editState: vehicleEditState,
    handleSubmit: putVehicle,
    user: user,
  });

  useEffect(() => {
    getTableData({ initialLoad: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setResponseData(vehicles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setResponseData, vehicles]);

  // Insert string name values for user readability
  useEffect(() => {
    if (!vehiclesLoading && !classesLoading && !statusesLoading) {
      dispatch(insertClassNames({ classTypes }));
      dispatch(insertStatusNames({ vehicleStatuses }));
    }
  }, [
    classTypes,
    classesLoading,
    dispatch,
    statusesLoading,
    vehicleStatuses,
    vehiclesLoading,
  ]);

  useEffect(() => {
    const { cellHeaders, cellData } = shapeCSVData(
      vehicleHeaders,
      responseData
    );

    setExportData({ cellHeaders, cellData });
  }, [responseData]);

  const getTableData = async ({
    initialLoad = false,
  }: {
    initialLoad?: boolean;
  }) => {
    setIsLoading(true);
    try {
      if (!initialLoad) {
        let params: any = {};
        Object.entries(searchData).forEach(
          ([key, value]: [key: string, value: any]) => {
            if (value.value) {
              params[key] = value.value;
            }
          }
        );
        await dispatch(getVehiclesByFilter(params));
      } else {
        await dispatch(getAllVehicles({}));
      }
      setIsSearching(false);
      setIsViewing(true);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  return (
    <div className="vehicles-page-container">
      {initialLoad && <ConnectOneLoader />}

      {isViewing && (
        <ConnectOneClickableTable
          data={responseData}
          label={"Vehicles"}
          headers={vehicleHeaders}
          sortGridData={handleSorting}
          exportData={exportData}
          handleRowSelection={handleRowSelection}
          handleSearch={() => setIsSearching(true)}
          handleReset={() => {
            getTableData({ initialLoad: true });
            handleResetSearch();
          }}
          resetButtonLabel={
            displayingFilteredData ? "Clear Search" : "Refresh Data"
          }
          actionButtonLabel={"Add New Vehicle"}
          handleActionButton={handleAdd}
          sortedValue={sortedValue}
          sortAscending={sortAscending}
          uploadButtonLabel={"Upload Vehicles"}
          uploadBucket={
            `${process.env.REACT_APP_VEHICLE_FILE_BUCKET_NAME}-${process.env.REACT_APP_USER_BRANCH}` ||
            ""
          }
          uploadFileType={
            // @ts-ignore
            FileTypes[process.env.REACT_APP_VEHICLE_FILE_TYPE] ||
            FileTypes["XLSX"]
          }
        />
      )}

      {isSearching && (
        <>
          <span className="co-modal-backdrop" />
          <ConnectOneSearchForm
            label={"Vehicles"}
            searchData={searchData}
            handleChange={handleFilterInputChange}
            handleCheckbox={handleFilterCheckboxChange}
            handleSearch={getTableData}
            isLoading={isLoading}
            handleClose={() => setIsSearching(false)}
          />
        </>
      )}

      {isEditing && (
        <VehiclesEditForm
          data={editData}
          label={"Vehicle"}
          handleChange={handleEditInputChange}
          handleGoBack={handleGoBack}
          handleSubmit={handleEditFormSubmit}
          handleCheckbox={handleCheckboxChange}
          isLoading={isLoading}
          isCreating={isCreating}
          isReadOnly={false}
        />
      )}
    </div>
  );
};
