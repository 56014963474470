import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Amplify } from "aws-amplify";

import { useIsMobile } from "../../../hooks";
import { AppDispatch } from "../../../store";
import {
  VerraLogo,
  HamburgerMenuIcon,
  routes,
  amplifyConfig,
} from "../../../assets";
import { ConnectOneButton, ConnectOneRoute } from "../../../components";
import {
  getAgencies,
  getAgencyServices,
  getAgencyStatuses,
  getFuelTypes,
  getServices,
  getClassTypes,
  getGeofences,
  getVehicleStatuses,
  getLocationStatuses,
  getLocationHierarchy,
  getServiceStatuses,
} from "../../../redux";
import { getGraphQLKey, resetIdleTimeout } from "../../../utils";

// @ts-ignore
import config from "../../../aws-exports";

import "./NavBar.scss";

export const ConnectOneNavBar = ({
  user,
  signOut,
}: {
  user: any;
  signOut: any;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const [activeRoute, setActiveRoute] = useState(window.location.pathname);
  const [showNav, setShowNav] = useState(!isMobile);

  const isAdmin = user.attributes["custom:userType"] === "admin";

  const [graphqlKey, setGraphQLKey] = useState("");

  const getGraphQLAPIKey = async () => {
    try {
      const key = await getGraphQLKey();
      setGraphQLKey(key);
    } catch (err) {
      console.log("Get GraphQL API Key Error: ", err);
    }
  };

  useEffect(() => {
    Amplify.configure({
      ...config,
      ...amplifyConfig,
      aws_appsync_graphqlEndpoint: graphqlKey,
    });
  }, [graphqlKey]);

  useEffect(() => {
    if (user) {
      // Listen for events that indicate user activity
      window.addEventListener("mousemove", resetIdleTimeout);
      window.addEventListener("keydown", resetIdleTimeout);
      window.addEventListener("touchstart", resetIdleTimeout);
    }
  }, [user]);

  useEffect(() => {
    getAppData();
    getGraphQLAPIKey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShowNav(!isMobile);
  }, [isMobile]);

  useEffect(() => {
    setActiveRoute(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  const handleNavigate = (path: string) => {
    if (isMobile) setShowNav(false);

    navigate(path);
  };

  const handleSignOut = () => {
    handleNavigate("/");
    signOut();
  };

  const getAppData = () => {
    dispatch(getGeofences());
    dispatch(getAgencies());
    dispatch(getAgencyServices());
    dispatch(getAgencyStatuses());
    dispatch(getFuelTypes());
    dispatch(getServices());
    dispatch(getServiceStatuses());
    dispatch(getClassTypes());
    dispatch(getVehicleStatuses());
    dispatch(getLocationStatuses());
    dispatch(getLocationHierarchy());
  };

  const truncatedLength = 12;

  return (
    <>
      {isMobile && (
        <div className="hamburger-menu">
          <span
            data-testid="hamburger-menu"
            className="hamburger-icon"
            onClick={() => setShowNav(!showNav)}
          >
            <HamburgerMenuIcon />
          </span>
          <span className="title">
            <VerraLogo />
            <h1>ConnectOne</h1>
          </span>
        </div>
      )}

      {showNav && (
        <div
          className={`${
            isMobile ? "mobile-navbar-container" : null
          } navbar-container`}
          data-testid="navbar"
        >
          <div className="logo-container">
            {!isMobile && (
              <div onClick={() => handleNavigate("/")}>
                <span className="title">
                  <VerraLogo />
                  <h1>ConnectOne</h1>
                </span>
              </div>
            )}

            {isMobile && (
              <div className="hamburger-menu">
                <span
                  data-testid="x-container"
                  className="x-container"
                  onClick={() => setShowNav(!showNav)}
                >
                  <span aria-hidden="true">&times;</span>
                </span>
                <span className="title">
                  <VerraLogo />
                  <h1>ConnectOne</h1>
                </span>
              </div>
            )}
          </div>

          <div className="nav-links" data-testid="visible-links">
            {routes.map((route) => (
              <ConnectOneRoute
                onClick={handleNavigate}
                route={route}
                activeRoute={activeRoute}
                key={route.name}
                isAdmin={isAdmin}
              />
            ))}
          </div>

          <div className="user-container">
            <p>
              {user.username.length > truncatedLength
                ? user.username.slice(0, truncatedLength) + "..."
                : user.username}
            </p>
            <ConnectOneButton
              label="Sign Out"
              disabled={false}
              handleClick={handleSignOut}
            />
          </div>
        </div>
      )}
    </>
  );
};
