import { Auth } from "aws-amplify";
import { CognitoRefreshToken } from "amazon-cognito-identity-js";
import {
  AdminCreateUserCommand,
  AdminDisableUserCommand,
  AdminEnableUserCommand,
  AdminUpdateUserAttributesCommand,
  CognitoIdentityProviderClient,
  DeliveryMediumType,
  ListUsersCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import { trimStringEndWhiteSpace } from "../utils";

export const getCognitoClient = async () => {
  const credentials = await getCognitoCredentials();

  return new CognitoIdentityProviderClient({
    region: process.env.REACT_APP_region,
    credentials: credentials,
  });
};

export const getUsersFromUserPool = async () => {
  const client = await getCognitoClient();
  const params = {
    UserPoolId: process.env.REACT_APP_userPoolId || "",
  };

  try {
    const command = new ListUsersCommand(params);
    return await client.send(command);
  } catch (err) {
    console.log("Err while getting user list", err);
    return null;
  }
};

export const updateUsersAttributes = async (user: any) => {
  const cognitoUser = await Auth.currentUserPoolUser();

  const accessToken = cognitoUser
    .getSignInUserSession()
    ?.getAccessToken()
    ?.getJwtToken();

  const client = await getCognitoClient();

  const params = {
    AccessToken: accessToken,
    UserAttributes: [
      {
        Name: "email",
        Value: trimStringEndWhiteSpace(user.email),
      },
      {
        Name: "email_verified", // Email needs to be verified in order to change
        Value: "true",
      },

      {
        Name: "custom:userType",
        Value: user.role,
      },
    ],
    Username: user.username,
    UserPoolId: process.env.REACT_APP_userPoolId || "",
  };

  if (user.phone !== "N/A") {
    // Cognito requires "+" in front of phone numbers
    if (user.phone.charAt(0) !== "+") {
      user.phone = `+${user.phone}`;
    }

    params.UserAttributes.push(
      {
        Name: "phone_number",
        Value: user.phone,
      },
      {
        Name: "phone_number_verified", // Phone needs to be verified in order to change
        Value: "true",
      }
    );
  }

  try {
    const command = new AdminUpdateUserAttributesCommand(params);
    return await client.send(command);
  } catch (err) {
    console.log("Err while updating user attributes", err);
    return null;
  }
};

export const addNewUser = async (user: any) => {
  const client = await getCognitoClient();

  const params = {
    UserAttributes: [
      {
        Name: "email",
        Value: trimStringEndWhiteSpace(user.email),
      },
      {
        Name: "custom:userType",
        Value: user.role,
      },
    ],
    Username: trimStringEndWhiteSpace(user.username),
    UserPoolId: process.env.REACT_APP_userPoolId || "",
    DesiredDeliveryMediums: ["EMAIL"] as DeliveryMediumType[],
  };

  if (user.phone) {
    // Cognito requires "+" in front of phone numbers
    if (user.phone.charAt(0) !== "+") {
      user.phone = `+${user.phone}`;
    }

    params.UserAttributes.push({
      Name: "phone_number",
      Value: user.phone,
    });
  }

  try {
    const command = new AdminCreateUserCommand(params);
    return await client.send(command);
  } catch (err) {
    console.log("Err while creating user", err);
    return null;
  }
};

export const changeUserStatus = async (username: string, enabled: any) => {
  const client = await getCognitoClient();

  const params = {
    Username: username,
    UserPoolId: process.env.REACT_APP_userPoolId || "",
  };

  try {
    let command;
    if (enabled) {
      command = new AdminEnableUserCommand(params);
    } else {
      command = new AdminDisableUserCommand(params);
    }
    return await client.send(command);
  } catch (err) {
    console.log("Err while enabling user", err);
    return null;
  }
};

export const getCognitoCredentials = async () => {
  return Auth.currentCredentials().then(async (credentials) => {
    return Auth.essentialCredentials(credentials);
  });
};

// Need a function that gets the session token expiration time
export const getSessionTokenExpiration = async () => {
  const user = await Auth.currentUserPoolUser();
  return user?.getSignInUserSession()?.getIdToken()?.getExpiration();
};

// Init the logout timeout for 60 minutes to match the Cognito session expiration time
let logoutAtExpiry: NodeJS.Timeout | null = setTimeout(() => {
  // Log out the user if the session expires
  window.removeEventListener("mousemove", resetIdleTimeout);
  window.removeEventListener("keydown", resetIdleTimeout);
  window.removeEventListener("touchstart", resetIdleTimeout);
  Auth.signOut();
}, 60 * 60 * 1000);

export const refreshCognitoToken = async () => {
  const user = await Auth.currentUserPoolUser();

  if (!user) {
    console.log("No user found");
    return;
  }

  // Get session token expiration time
  const sessionExp = await getSessionTokenExpiration();
  const sessionExpDate = new Date(sessionExp * 1000);
  const reamingTime = sessionExpDate?.getTime() - Date.now();

  // Do not refresh is the session is more than 30 minutes to expire
  if (reamingTime > 30 * 60 * 1000) {
    return;
  }

  const refreshToken = new CognitoRefreshToken({
    RefreshToken: user.getSignInUserSession()?.getRefreshToken()?.getToken(),
  });

  return new Promise((resolve, reject) => {
    user.refreshSession(refreshToken, (err: any, session: any) => {
      if (err) {
        reject(err);
      } else {
        // Clear any existing logout timeout
        if (logoutAtExpiry) {
          clearTimeout(logoutAtExpiry);
        }

        // Set a new logout timeout
        let sessionExpiryTime = sessionExp * 1000; // Convert to milliseconds
        logoutAtExpiry = setTimeout(() => {
          // Log out the user if the session expires
          window.removeEventListener("mousemove", resetIdleTimeout);
          window.removeEventListener("keydown", resetIdleTimeout);
          window.removeEventListener("touchstart", resetIdleTimeout);
          Auth.signOut();
        }, sessionExpiryTime - Date.now());

        resolve(session);
      }
    });
  });
};

export const resetIdleTimeout = async () => {
  // Get session expiration time
  const sessionExp = await getSessionTokenExpiration();
  const sessionExpDate = new Date(sessionExp * 1000);

  const reamingTime = sessionExpDate?.getTime() - Date.now();

  // If session is up to expire in next 10 minutes check if user is active, if so refresh token
  if (reamingTime < 10 * 60 * 1000) {
    await refreshCognitoToken();
  }
};

export const getUserAccessToken = async () => {
  const user = await Auth.currentUserPoolUser();
  return user.getSignInUserSession()?.getIdToken().getJwtToken();
};
