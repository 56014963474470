export const transactionHeaderNames = [
  { name: "ID", value: "_id" },
  { name: "VIN", value: "vin" },
  { name: "License Plate", value: "licensePlate" },
  { name: "State", value: "licensePlateState" },
  { name: "Passenger Count", value: "passengersCount" },
  { name: "Vehicle Class", value: "className" },
  { name: "Plaza Name", value: "plazaName" },
  { name: "Plaza ID", value: "externalPlazaId" },
  { name: "Status", value: "status" },
  { name: "Toll Amount", value: "tollAmount" },
  { name: "Charge Type", value: "chargeType" },
  { name: "Agency", value: "agencyName" },
  { name: "Timezone", value: "timeZone" },
  { name: "Toll Time UTC", value: "tollDateTime" },
  { name: "Toll Time Local", value: "tollDateTimeLocal" },
  { name: "Is Registered", value: "isRegisteredAccount" },
];

export const rucHeaderNames = [
  { name: "ID", value: "_id" },
  { name: "VIN", value: "deviceid" },
  { name: "Geofence ID", value: "geofenceId" },
  { name: "Geofence Name", value: "geofenceName" },
  { name: "Entry Time UTC", value: "entryTime" },
  { name: "Entry Time Local", value: "entryTimeLocal" },
  { name: "Exit Time UTC", value: "exitTime" },
  { name: "Exit Time Local", value: "exitTimeLocal" },
  { name: "Odometer (KM)", value: "totalOdometer" },
  { name: "Type", value: "type" },
];

export const gnssTollHeaderNames = [
  { name: "ID", value: "_id" },
  { name: "VIN", value: "vin" },
  { name: "Vehicle Class", value: "vehicleClass" },
  { name: "License Plate", value: "licensePlate" },
  { name: "State", value: "licensePlateState" },
  { name: "Toll Amount", value: "tollAmount" },
  { name: "Toll Time UTC", value: "tollDateTime" },
  { name: "Toll Time Local", value: "tollDateTimeLocal" },
  { name: "Agency", value: "agency" },
  { name: "Plaza", value: "plaza" },
  { name: "Plaza ID", value: "externalPlazaId" },
  { name: "Is Registered", value: "isRegisteredAccount" },
  { name: "Passenger Count", value: "passengersCount" },
  { name: "Timezone", value: "timeZone" },
];

export const agencyTollHeaderNames = [
  { name: "_id", value: "_id" },
  { name: "iBt_Queue_Id", value: "iBt_Queue_Id" },
  { name: "aET_TRAN_NUMBER", value: "aET_TRAN_NUMBER" },
  { name: "violation_number", value: "violation_number" },
  { name: "plaza", value: "plaza" },
  { name: "lane", value: "lane" },
  { name: "tran_Time", value: "tran_Time" },
  { name: "lane_Tran", value: "lane_Tran" },
  { name: "number_Of_Image", value: "number_Of_Images" },
  { name: "or_Ocr_Plate", value: "or_Ocr_Plate" },
  { name: "or_Ocr_State", value: "or_Ocr_State" },
  { name: "or_Ocr_Confidence", value: "or_Ocr_Confidence" },
  { name: "or_Ocr_Plate_Type", value: "or_Ocr_Plate_Type" },
  { name: "agency_Created_Time", value: "agency_Created_Time" },
  { name: "bo_Ocr_Confidence", value: "bo_Ocr_Confidence" },
  { name: "bo_Ocr_Plate", value: "bo_Ocr_Plate" },
  { name: "bo_Ocr_State", value: "bo_Ocr_State" },
  { name: "bo_Ocr_Plate_Type", value: "bo_Ocr_Plate_Type" },
  { name: "fileName", value: "fileName" },
  { name: "processed_Time", value: "procesed_Time" },
  { name: "agencyName", value: "agencyName" },
  { name: "timeZone", value: "timeZone" },
  { name: "gnssTollActivityId", value: "gnssTollActivityId" },
  { name: "tran_Time_Local", value: "tran_Time_Local" },
];

export const vehicleHeaders = [
  { name: "Vin", value: "vin" },
  { name: "Nickname", value: "nickName" },
  { name: "Provider", value: "provider" },
  { name: "Status", value: "statusName" },
  { name: "License Plate", value: "licensePlate" },
  { name: "License Plate State", value: "licensePlateState" },
  { name: "Plate Type", value: "plateType" },
  { name: "Class", value: "className" },
  { name: "Fuel Type", value: "fuelType" },
  { name: "Year", value: "year" },
  { name: "Make", value: "make" },
  { name: "Model", value: "model" },
  { name: "Axles", value: "axles" },
  { name: "Tires", value: "tires" },
  { name: "Device Type", value: "deviceType" },
  { name: "Toll Transponder ID", value: "tollTransponderId" },
  { name: "Fuel Charge ID", value: "fuelChargeId" },
  { name: "OBD2ID", value: "obd2ID" },
  { name: "Equity", value: "equity" },
  {
    name: "Receive Messages",
    parentValue: "communicationPreferences",
    value: "receiveInfotainmentMessages",
  },
  {
    name: "Plate Start Effective Date",
    value: "licensePlateStartEffectiveDate",
  },
  { name: "Plate End Effective Date", value: "licensePlateEndEffectiveDate" },
  { name: "Vehicle Start Effective Date", value: "vehicleStartEffectiveDate" },
  { name: "Vehicle End Effective Date", value: "vehicleEndEffectiveDate" },
  { name: "Created Date", value: "createDate" },
  { name: "Last Updated Date", value: "lastUpdateDate" },
  { name: "Last Updated By", value: "lastUpdatedBy" },
];

export const vehicleActivityHeaders = [
  { name: "Vin", value: "vin" },
  { name: "Nickname", value: "nickName" },
  { name: "Status", value: "statusName" },
  { name: "License Plate", value: "licensePlate" },
  { name: "License Plate State", value: "licensePlateState" },
  { name: "Latest Transaction", value: "latestTransaction" },
];

export const VinLookupHeaders = [
  { name: "Vin", value: "vin" },
  { name: "Nickname", value: "nickName" },
  { name: "License Plate", value: "licensePlate" },
  { name: "License Plate State", value: "licensePlateState" },
];

export const ServicesHeaders = [
  { name: "Name", value: "name" },
  { name: "Description", value: "description" },
  { name: "Status", value: "status" },
  { name: "Created Date", value: "createdDate" },
  { name: "Last Updated Date", value: "lastupdateDate" },
  { name: "Last Updated By", value: "modifiedBy" },
];

export const LocationHeaders = [
  { name: "Plaza Name", value: "name" },
  { name: "Plaza ID", value: "plazaId" },
  { name: "Agency", value: "agencyName" },
  { name: "State", value: "agencyState" },
  { name: "Road Name", value: "roadName" },
  { name: "Location Type", value: "locationType" },
  { name: "Status", value: "statusName" },
  { name: "Verified", value: "verified" },
  { name: "Notes", value: "notes" },
];

export const GeofenceHeaders = [
  { name: "Agency", value: "agencyName" },
  { name: "PlazaID", value: "plazaId" },
  { name: "PlazaName", value: "name" },
  { name: "RoadName", value: "roadName" },
  { name: "LocationType", value: "locationType" },
  { name: "LocationSubType", value: "locationSubType" },
  { name: "Long", value: "longitude" },
  { name: "Lat", value: "latitude" },
  { name: "Width", value: "width" },
  { name: "Height", value: "height" },
  { name: "Course", value: "course" },
  { name: "Notes", value: "notes" },
];

export const RatesHeaders = [
  { name: "Passengers", value: "passengersCount", required: true },
  { name: "Transponder Rate", value: "avi", required: true },
  { name: "Plate Rate", value: "plateRead", required: true },
  { name: "Cash Rate", value: "cashRate" },
  { name: "Negotiated Rate", value: "negotiatedRate" },
  { name: "HOV Rate", value: "hovRate" },
  { name: "Equity Rate", value: "equityRate" },
];

export const vehicleTracesHeaderNames = [
  { name: "ID", value: "_id" },
  { name: "VIN", value: "vin" },
  { name: "Plate", value: "licensePlate" },
  { name: "Data Source", value: "dataSource" },
  { name: "Plate State", value: "licensePlateState" },
  { name: "Nick Name", value: "nickName" },
  { name: "Latitude", value: "latitude" },
  { name: "Longitude", value: "longitude" },
  { name: "Timestamp Local", value: "timeStampLocal" },
  { name: "Timestamp", value: "timestamp" },
  { name: "Tolling Speed", value: "tollingSpeed" },
  { name: "Passenger Present", value: "passengerPresent" },
  { name: "Course", value: "course" },
  { name: "Speedometer", value: "speedometer" },
  { name: "Odometer", value: "odometer" },
  { name: "Device Name", value: "deviceName" },
  { name: "Altitude", value: "altitude" },
  { name: "Time Stamp UTC", value: "timeStampUTC" },
  { name: "Time Zone", value: "timeZone" },
  { name: "Horizontal Accuracy", value: "horizontalAccuracy" },
  { name: "Vehicle State", value: "vehicleState" },
];

export const rateHeaderNames = [
  { name: "Agency", value: "agencyName" },
  { name: "State", value: "state" },
  { name: "Location Name", value: "name" },
  { name: "Road Name", value: "roadName" },
  { name: "Vehicle Class", value: "vehicleClass" },
  { name: "Day Of Week Start", value: "dayOfWeekStart" },
  { name: "Time Of Day Start", value: "timeOfDayStart" },
  { name: "Day Of Week End", value: "dayOfWeekEnd" },
  { name: "Time Of Day End", value: "timeOfDayEnd" },
  { name: "Passenger Count", value: "passengersCount" },
  { name: "Transponder Rate", value: "avi" },
  { name: "Plate Rate", value: "plateRead" },
  { name: "Cash Rate", value: "cashRate" },
  { name: "Negotiated Rate", value: "negotiatedRate" },
  { name: "HOV Rate", value: "hovRate" },
  { name: "Equity Rate", value: "equityRate" },
  { name: "Start Effective Date", value: "startEffectiveDate" },
  { name: "End Effective Date", value: "endEffectiveDate" },
];

export const userHeaderNames = [
  { name: "Username", value: "username" },
  { name: "Email", value: "email" },
  { name: "Phone", value: "phone" },
  { name: "Role", value: "role" },
  { name: "Enabled", value: "enabled" },
  { name: "Create Date", value: "createDate" },
  { name: "Last Modified Date", value: "lastModifiedDate" },
];

export const agencyHeaders = [
  { name: "Name", value: "name" },
  { name: "Status", value: "statusName" },
  { name: "Currency", value: "currency" },
  { name: "Country", value: "country" },
  { name: "State", value: "state" },
  { name: "Charge Type", value: "chargeType" },
  { name: "Max HOV", value: "chargeHov" },
  { name: "Services", value: "servicesString" },
  { name: "Time Zone", value: "timeZone" },
];

export const tollImportGroupedHeaders = [
  { name: "Invoke ID", value: "invokeId" },
  { name: "Total Files Ran", value: "countFilesRan" },
  { name: "Total Success", value: "totalSuccess" },
  { name: "Total Failed", value: "totalFailed" },
  { name: "Total Newly Created", value: "totalNewlyCreated" },
  { name: "Invoke Date Time", value: "invokeDateTime" },
  { name: "Invoked User", value: "invokedUser" },
];

export const tollImportMetadataHeaders = [
  { name: "Filename", value: "fileName" },
  { name: "Success Count", value: "successCount" },
  { name: "Failed Count", value: "failureCount" },
  { name: "Newly Created Count", value: "newlyCreatedCount" },
  { name: "Invoke Date Time", value: "invokeDateTime" },
  { name: "Process Completion Date Time", value: "processCompletedDateTime" },
  { name: "Invoke User", value: "invokedUser" },
];
