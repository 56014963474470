import { TextAreaField, Text } from "@aws-amplify/ui-react";

import { useIsMobile } from "../../../hooks";

import "./TextBox.scss";

export const ConnectOneTextBox = ({
  handleChange,
  type,
  name,
  placeholder = "",
  label = undefined,
  value = undefined,
  required = false,
  readOnly = false,
}: {
  handleChange: any;
  label?: string;
  type: string;
  name: string;
  placeholder?: string;
  value?: string;
  required?: boolean;
  readOnly?: boolean;
}) => {
  const isMobile = useIsMobile();

  const requiredStatus = required && !value;

  return (
    <div
      className={`${isMobile ? "mobile-input" : null} ${type}-input input`}
      data-testid={`${name}-text-box`}
    >
      <TextAreaField
        label={
          <Text>
            {label}
            {requiredStatus && (
              <Text as="span" color="red">
                {" "}
                *
              </Text>
            )}
          </Text>
        }
        name={name}
        onChange={handleChange}
        data-testid={`${label}-input`}
        placeholder={placeholder}
        value={value}
        isRequired={required}
        disabled={readOnly}
        rows={4}
      />
    </div>
  );
};
