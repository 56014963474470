import { useState } from "react";
import { Marker, Popup } from "react-map-gl";

import { MarkerIcon, vehicleTracesHeaderNames } from "../../assets";
import {
  convertToUserTimezone,
  getMarkerColor,
  handleViewOnGoogle,
} from "../../utils";

import "./MapMarker.scss";

export const ConnectOneMapMarker = ({
  tracePoint,
  startPoint = false,
  endPoint = false,
}: {
  tracePoint: any;
  startPoint?: boolean;
  endPoint?: boolean;
}) => {
  const [showPopup, setShowPopup] = useState(false);

  const handleMarkerClick = ({ originalEvent }: { originalEvent: any }) => {
    originalEvent.stopPropagation();
    setShowPopup(true);
  };

  const showBalloon = startPoint || endPoint;

  return (
    <div
      data-testid={`${tracePoint.vin}-marker`}
      className="map-marker-container"
    >
      <Marker
        latitude={tracePoint.latitude}
        longitude={tracePoint.longitude}
        onClick={handleMarkerClick}
        color={getMarkerColor({
          startPoint,
          endPoint,
          tollingSpeed: tracePoint.tollingSpeed,
          dataSource: tracePoint.dataSource,
        })}
      >
        {!showBalloon && (
          <div
            className="map-marker"
            style={{
              transform: `rotate(${tracePoint.course - 45}deg)`, // -45 to account for askew svg
              WebkitTransform: `rotate(${tracePoint.course - 45}deg)`, // Handles ios safari
            }}
          >
            <MarkerIcon
              traceData={tracePoint}
              startPoint={startPoint}
              endPoint={endPoint}
            />
          </div>
        )}
      </Marker>
      {showPopup && (
        <Popup
          latitude={tracePoint.latitude}
          longitude={tracePoint.longitude}
          offset={{ bottom: [0, -40] }}
          onClose={() => setShowPopup(false)}
          maxWidth={"100%"}
        >
          <div className="popup-headers">
            <h1>{tracePoint.vin}</h1>
            <p
              onClick={() =>
                handleViewOnGoogle([tracePoint.longitude, tracePoint.latitude])
              }
            >
              View on Google maps
            </p>
          </div>
          <div className="popup-content">
            <p>
              <strong>Timestamp Local:</strong>{" "}
              {convertToUserTimezone(tracePoint.timeStampUTC)}
            </p>
            {vehicleTracesHeaderNames.map((header: any) => {
              if (
                tracePoint[header.value] === undefined ||
                tracePoint[header.value] === null
              )
                return null;
              return (
                <p>
                  <strong>{header.name}:</strong> {tracePoint[header.value]}
                </p>
              );
            })}
          </div>
        </Popup>
      )}
    </div>
  );
};
