import { useEffect, useState } from "react";
import { addDays, addHours, format } from "date-fns";

import { VehicleTraces } from "../../../types";
import { shapeTraceData, sortGridData } from "../../../utils";
import { useIsMobile, useReport } from "../../../hooks";
import { getVehicleTracesAPI, getVehicleTracesCountAPI } from "../../../axios";
import {
  ConnectOneLoader,
  ConnectOneTable,
  ConnectOneTableControls,
} from "../../../components";

export const VehicleTracesReport = () => {
  const isMobile = useIsMobile();
  const {
    reportData,
    currentPageIndex,
    totalPages,
    isLoading,
    headers,
    exportData,
    setTotalPages,
    exportFileName,
    setIsLoading,
    setReportData,
    setCurrentPageIndex,
    userFriendlyDateInsert,
  } = useReport({ isMobile, reportType: "vehicleTraces", dataPerPage: 1000 });

  const [dataToDisplay, setDataToDisplay] = useState([] as VehicleTraces[]);
  const [filterValues, setFilterValues] = useState({
    vin: {
      type: "search",
      label: "Vin",
      value: "",
    },
    dateFrom: {
      type: "datetime-local",
      label: "Date From",
      value: `${format(addDays(new Date(), -1), "yyyy-MM-dd")}T00:00`,
    },
    dateTo: {
      type: "datetime-local",
      label: "Date To",
      value: `${format(new Date(), "yyyy-MM-dd")}T${format(
        addHours(new Date(), 1).getTime(),
        "HH:",
      )}00`,
    },
  });

  const [sortedValue, setSortedValue] = useState("");
  const [sortAscending, setSortAscending] = useState(true);

  const submitButtonEnabled = Object.values(filterValues).some(
    (filterValue: any) => !!filterValue.value,
  );

  // Brought handleSorting out of useReport since it has state specific to this report
  const handleSorting = (header: string) => {
    if (sortedValue === header && !sortAscending) {
      const sortedReportData = sortGridData({
        unsortedData: reportData,
        sortValue: header,
        ascending: false,
      });
      setSortAscending(true);
      setReportData(sortedReportData);
      setDataToDisplay(sortedReportData);
      return;
    }
    const sortedReportData = sortGridData({
      unsortedData: reportData,
      sortValue: header,
      ascending: true,
    });
    setSortedValue(header);
    setSortAscending(false);
    setReportData(sortedReportData);
    setDataToDisplay(sortedReportData);
    return;
  };

  // For vehicle traces we need to get the count of all traces in the date range
  // Then show the first 1000 results
  // If there are more than 1000 results, we need to show the pagination
  // When user clicks next, we need to get the next 1000 results based on the page number
  const getTableData = async (pageNumber: number) => {
    setIsLoading(true);
    try {
      setDataToDisplay([] as VehicleTraces[]);
      if (!pageNumber) {
        getVehicleTracesCountAPI({
          vin: filterValues.vin.value,
          dateFrom: new Date(filterValues.dateFrom.value).valueOf(),
          dateTo: new Date(filterValues.dateTo.value).valueOf(),
        }).then((res) => {
          setTotalPages(Math.ceil(res / 1000));
          setCurrentPageIndex(1);
        });
      }
      const res: any = await getVehicleTracesAPI(
        {
          vin: filterValues.vin.value,
          dateFrom: new Date(filterValues.dateFrom.value).valueOf(),
          dateTo: new Date(filterValues.dateTo.value).valueOf(),
        },
        pageNumber,
      );
      const tracePointData = shapeTraceData(res);
      const data = userFriendlyDateInsert({
        data: tracePointData,
        localDate: "tollDateTimeLocal",
        utcDate: "tollDateTime",
      });
      setReportData(data);
      setDataToDisplay(data);
    } catch (err) {
      setReportData([] as VehicleTraces[]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    // On any valid page change get the data
    if (currentPageIndex) getTableData(currentPageIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageIndex]);

  return (
    <>
      <ConnectOneTableControls
        fullData={reportData}
        dataIsLoading={isLoading}
        totalPages={totalPages}
        currentPageIndex={currentPageIndex}
        setCurrentPageIndex={setCurrentPageIndex}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        handleFilterSubmit={() => {
          // On new search set the current page index to null to reset table/count
          setCurrentPageIndex(null);
          getTableData(null);
        }}
        submitButtonDisabled={!submitButtonEnabled}
        fileName={exportFileName(
          `VEHICLE_TRACES_REPORT_PAGE_${currentPageIndex}`,
          filterValues,
        )}
        exportData={exportData}
      />

      {isLoading && (
        <span className="data-table-loader">
          <ConnectOneLoader />
        </span>
      )}

      <ConnectOneTable
        data={dataToDisplay}
        headers={headers}
        caption="Vehicle Traces"
        sortGridData={handleSorting}
        sortAscending={sortAscending}
        sortedValue={sortedValue}
      />
    </>
  );
};
