import "./Unauth.scss";

export const UnauthPage = () => {
  return (
    <div className="unauth-container">
      <h1>Unauthorized Access</h1>
      <p>You are not authorized to view this page.</p>
      <p>Please contact your system administrator for further assistance.</p>
    </div>
  );
};
