import { useState } from "react";
import { Heading } from "@aws-amplify/ui-react";

import { ConnectOneButton } from "../..";
import { useIsMobile } from "../../../hooks";
import { LockedIcon, UnlockedIcon } from "../../../assets";
import { GeofenceModalReadOnly } from "./GeofenceModalReadOnly";
import { GeofenceDetailsEditForm } from "./GeofenceDetailsEditForm";
import { LocationsEditForm } from "../../../pages";
import { handleViewOnGoogle } from "../../../utils";

import "./GeofenceModal.scss";

export const GeofenceModal = ({
  geofence = {},
  isLoading,
  userIsReadOnly,
  userIsAdmin,
  handlePropertyChange,
  handleSubmit,
  handleDelete,
  handleUndo,
  onClose,
  onEditViewClose,
  isSavingGeofence,
  changesMade,
  handleScheduleOrRateChange,
  handleInsertScheduleOrRate,
  handleRemoveScheduleOrRate,
  handleScheduleSave,
  handleUndoScheduleChanges,
  handleLockGeofence,
  handleGeofenceClone,
  handleRemoveExclusiveDate,
  handleGetAllEntryPoints,
}: {
  geofence?: any;
  isLoading: boolean;
  userIsReadOnly: boolean;
  userIsAdmin: boolean;
  handlePropertyChange: any;
  handleSubmit: any;
  handleDelete: any;
  handleUndo: any;
  onClose: any;
  onEditViewClose: any;
  isSavingGeofence: boolean;
  changesMade: boolean;
  handleScheduleOrRateChange: any;
  handleInsertScheduleOrRate: any;
  handleRemoveScheduleOrRate: any;
  handleScheduleSave: any;
  handleUndoScheduleChanges: any;
  handleLockGeofence: any;
  handleGeofenceClone: any;
  handleRemoveExclusiveDate: any;
  handleGetAllEntryPoints?: any;
}) => {
  const isMobile = useIsMobile();
  const [isEditing, setIsEditing] = useState(false);
  // If the geofence has no name, we assume it is being created
  const [isCreating, setIsCreating] = useState(!geofence?.name);

  // Handle validation for the submit button
  const requiredValues = ["name", "agencyId", "locationType"];
  const submitEnabled = requiredValues.every((value) => {
    return (
      geofence?.properties[value] && geofence?.properties[value]?.length > 0
    );
  });

  return (
    <div
      className={`${isMobile ? "mobile-geofence-modal" : "geofence-modal"} ${
        isCreating ? "creating-geofence-modal" : null
      }`}
      data-testid={`${geofence?.name || "null"}-modal`}
    >
      <div className="modal-headers-container">
        <div className="modal-headers">
          {isMobile ? (
            <div
              className="x-container-mobile"
              onClick={() => onClose({ isCreating })}
              data-testid="search-modal-close-btn"
            >
              <span aria-hidden="true">&times;</span>
            </div>
          ) : (
            <div
              className="x-container"
              onClick={() => onClose({ isCreating })}
              data-testid="search-modal-close-btn"
            >
              <span aria-hidden="true">&times;</span>
            </div>
          )}
          <Heading level={6}>{geofence?.name || "Create New Geofence"}</Heading>
          {userIsAdmin && !isCreating ? (
            <div
              className="lock-container"
              onClick={() =>
                handleLockGeofence(!geofence.properties.lockStatus)
              }
              data-testid="lock-icon"
            >
              {geofence.properties.lockStatus ? (
                <LockedIcon
                  className="lock-icon"
                  title="This locations coordinates are locked from being edited"
                />
              ) : (
                <UnlockedIcon
                  className="lock-icon"
                  title="Click here to lock the editing of this locations coordinates"
                />
              )}
            </div>
          ) : (
            <span />
          )}
        </div>

        {!!geofence?.properties?.center?.length && !isCreating && (
          <h2 onClick={() => handleViewOnGoogle(geofence?.properties?.center)}>
            View on Google Maps
          </h2>
        )}
      </div>

      <div className="geofence-form">
        {isCreating ? (
          <GeofenceDetailsEditForm
            geofence={geofence}
            handlePropertyChange={handlePropertyChange}
            handleSubmitChange={handleSubmit}
            isCreating={isCreating}
            isLoading={isLoading}
          />
        ) : (
          <GeofenceModalReadOnly geofence={geofence} />
        )}
      </div>

      {isEditing && (
        <LocationsEditForm
          geofence={geofence}
          isLoading={isLoading}
          handleChange={handlePropertyChange}
          handleGoBack={() => {
            setIsEditing(false);
            onEditViewClose();
          }}
          handleSubmit={handleSubmit}
          handleInsertScheduleOrRate={handleInsertScheduleOrRate}
          handleScheduleOrRateChange={handleScheduleOrRateChange}
          handleRemoveScheduleOrRate={handleRemoveScheduleOrRate}
          handleRemoveExclusiveDate={handleRemoveExclusiveDate}
          handleScheduleSave={handleScheduleSave}
          handleUndoScheduleChanges={handleUndoScheduleChanges}
        />
      )}

      {!userIsReadOnly && (
        <>
          {isCreating ? (
            <div className="button-container">
              <ConnectOneButton
                label="Cancel"
                handleClick={() => handleUndo({ isCreating })}
                color={"red"}
              />
              <ConnectOneButton
                label="Save Location"
                disabled={!submitEnabled}
                handleClick={handleSubmit}
                isLoading={isSavingGeofence}
              />
            </div>
          ) : (
            <div className="button-container">
              {userIsAdmin && !isMobile && (
                <ConnectOneButton
                  label="Clone Location"
                  handleClick={async () => {
                    const isCloning = await handleGeofenceClone();
                    if (isCloning) setIsCreating(true);
                  }}
                />
              )}
              <ConnectOneButton
                label="Edit Properties"
                handleClick={() => {
                  setIsEditing(true);
                  if (geofence.properties?.locationSubType === "DISTANCEEXIT") {
                    handleGetAllEntryPoints();
                  }
                }}
              />
              {!isMobile && (
                <ConnectOneButton
                  label="Undo Changes"
                  disabled={!changesMade}
                  handleClick={handleUndo}
                />
              )}
              <ConnectOneButton
                label="Delete Location"
                handleClick={handleDelete}
                color={"red"}
              />
              {!isMobile && (
                <ConnectOneButton
                  label="Save Coordinates"
                  disabled={!changesMade}
                  handleClick={handleSubmit}
                  isLoading={isSavingGeofence}
                />
              )}
            </div>
          )}
        </>
      )}

      {isMobile && (
        <p onClick={onClose} className="mobile-close-button">
          CLOSE
        </p>
      )}
    </div>
  );
};
