import { useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@aws-amplify/ui-react";
import { format } from "date-fns";
import { CSVLink } from "react-csv";
import InfiniteScroll from "react-infinite-scroll-component";

import { ConnectOneButton, ConnectOneUploadButton } from "../..";
import { ArrowIcon, CSVDownloadIcon } from "../../../assets";
import { useIsMobile } from "../../../hooks";

import { Autocomplete } from "@aws-amplify/ui-react";

import "./ClickableTable.scss";

export const ConnectOneClickableTable = ({
  data,
  label = "",
  headers,
  sortGridData,
  handleRowSelection,
  handleGoBack = undefined,
  actionButtonLabel = undefined,
  exportData = undefined,
  handleActionButton = undefined,
  handleSearch = undefined,
  handleReset = undefined,
  resetButtonLabel = undefined,
  uploadButtonLabel = undefined,
  uploadBucket = undefined,
  uploadFileType = undefined,
  tableBodyHeight = "160px",
  sortedValue,
  sortAscending,
  autoCompleteSearch = false,
}: {
  data: any;
  label: string;
  headers: any[];
  sortGridData: any;
  handleRowSelection: any;
  handleGoBack?: any;
  actionButtonLabel?: string;
  exportData?: any;
  handleActionButton?: any;
  handleSearch?: any;
  handleReset?: any;
  resetButtonLabel?: string;
  uploadButtonLabel?: string;
  uploadBucket?: string;
  uploadFileType?: string;
  tableBodyHeight?: string;
  sortedValue?: string;
  sortAscending?: boolean;
  autoCompleteSearch?: boolean;
}) => {
  const isMobile = useIsMobile();

  const [dataToDisplay, setDataToDisplay] = useState([] as any);

  const chunkSize = 250;

  useEffect(() => {
    setDataToDisplay([...data].splice(0, chunkSize));
  }, [data]);

  const renderMoreData = () => {
    const newData = [...data].splice(dataToDisplay.length, chunkSize);
    setDataToDisplay([...dataToDisplay, ...newData]);
  };

  // AutoComplete Logic
  const [autoCompleteValue, setAutoCompleteValue] = useState("");

  const autoCompleteOptions = dataToDisplay
    .map((item: any, key: any) => ({
      _id: item._id || "",
      label: item.name || "",
    }))
    .sort((a: any, b: any) => a.label.localeCompare(b.label));

  const onAutoCompleteChange = (event: any) => {
    setAutoCompleteValue(event.target.value);
  };

  const onAutocompleteSelect = (option: any) => {
    const { _id, label } = option;
    setAutoCompleteValue(label);
    // Added handleRowSelection to display modal on select
    handleRowSelection(dataToDisplay.find((record: any) => record._id === _id));
    onAutoCompleteClear();
  };

  const onAutoCompleteClear = () => {
    setAutoCompleteValue("");
  };

  const Rows = ({
    data,
    headers,
    handleRowSelection,
  }: {
    data: any;
    headers: any[];
    handleRowSelection: any;
  }) => {
    return data.map((row: any, index: number) => {
      return (
        <TableRow key={`row-${index}`} as="tr">
          {headers.map((header: any) => {
            let cellValue: any = "";

            if (
              header.parentValue &&
              row.hasOwnProperty(header.parentValue) &&
              row[header.parentValue].hasOwnProperty(header.value)
            ) {
              cellValue = row[header.parentValue][header.value];
            } else if (
              row.hasOwnProperty(header.value) &&
              row[header.value] !== null
            ) {
              cellValue = row[header.value];
            }

            if (typeof cellValue === "object" && cellValue !== null) {
              cellValue = cellValue.label;
            }

            if (typeof cellValue === "boolean") {
              cellValue = cellValue ? "True" : "False";
            }

            // Handle empty cells
            if (cellValue === "") {
              cellValue = "N/A";
            }

            return (
              <TableCell
                onClick={() => handleRowSelection(row, index)}
                key={`row-${header.name}-data-${index}`}
                data-testid={`${cellValue}-cell-data`}
                as="td"
              >
                {cellValue}
              </TableCell>
            );
          })}
        </TableRow>
      );
    });
  };

  return (
    <div
      className={`clickable-table-container ${
        isMobile ? "mobile-clickable-table-container" : null
      }`}
      data-testid={`${label}-clickable-table`}
    >
      <div
        className={`${
          actionButtonLabel ? "clickable-table-with-button" : null
        }`}
      >
        <div
          className={`${
            handleGoBack || handleSearch || autoCompleteSearch
              ? "headers-with-button"
              : null
          } clickable-table-headers`}
        >
          <div className="label-with-button">
            {!!exportData?.cellHeaders && !!exportData?.cellData && (
              <div className="csv-button">
                <CSVLink
                  filename={`${label}-Export-${format(
                    new Date(),
                    "MM/dd/yyyy hh:mm"
                  )}`}
                  headers={exportData.cellHeaders}
                  data={exportData.cellData}
                  className="csv-text"
                >
                  <CSVDownloadIcon className={"csv-icon"} />
                </CSVLink>
              </div>
            )}
            <h1>{label}</h1>
          </div>
          <span />
          {/* AutoComplete section */}
          {autoCompleteSearch && (
            <div className="label-with-button">
              <span className="reset-button">
                <Autocomplete
                  label="Default Autocomplete"
                  options={autoCompleteOptions}
                  value={autoCompleteValue}
                  placeholder="Search here..."
                  onChange={onAutoCompleteChange}
                  onClear={onAutoCompleteClear}
                  onSelect={onAutocompleteSelect}
                />
              </span>
            </div>
          )}
          {!autoCompleteSearch && (
            <div className="label-with-button">
              {handleReset && (
                <span className="reset-button">
                  <ConnectOneButton
                    label={resetButtonLabel}
                    handleClick={handleReset}
                  />
                </span>
              )}
              {handleSearch && (
                <ConnectOneButton label="Search" handleClick={handleSearch} />
              )}
            </div>
          )}

          {handleGoBack && (
            <ConnectOneButton
              label="Back"
              handleClick={() => handleGoBack("results")}
            />
          )}
        </div>
        <div className="clickable-table-table">
          <InfiniteScroll
            dataLength={dataToDisplay.length}
            next={renderMoreData}
            hasMore={dataToDisplay.length < data.length}
            loader={null}
            scrollableTarget="scrollable-rows"
            height={`calc(100vh - ${tableBodyHeight})`}
          >
            <Table highlightOnHover={true}>
              <TableHead>
                <TableRow>
                  {headers.map((header, index) => {
                    return (
                      <TableCell
                        as="th"
                        onClick={() => sortGridData(header.value)}
                        key={`header-${index}`}
                      >
                        <div
                          className="cell-header"
                          data-testid={`${header.value}-header`}
                        >
                          <p>{header.name}</p>
                          {sortedValue === header.value && (
                            <span
                              className={
                                sortAscending
                                  ? "arrow-icon-ascending"
                                  : "arrow-icon-descending"
                              }
                            >
                              <ArrowIcon />
                            </span>
                          )}
                        </div>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>

              <TableBody as="tbody" className="scrollable-rows">
                <Rows
                  data={dataToDisplay}
                  headers={headers}
                  handleRowSelection={handleRowSelection}
                />
              </TableBody>
            </Table>
          </InfiniteScroll>
        </div>

        <div className="button-container">
          {uploadButtonLabel && !isMobile && (
            <span className="upload-button">
              <ConnectOneUploadButton
                label={uploadButtonLabel}
                uploadBucket={uploadBucket}
                fileType={uploadFileType}
              />
            </span>
          )}

          {actionButtonLabel && (
            <span className="action-button">
              <ConnectOneButton
                label={actionButtonLabel}
                handleClick={handleActionButton}
              />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
