import { useState } from "react";
import { CSVLink } from "react-csv";
import { Pagination } from "@aws-amplify/ui-react";

import { ConnectOneFilterBar, ConnectOneLookupModal } from "../..";
import { useIsMobile } from "../../../hooks";
import { CSVDownloadIcon } from "../../../assets";

import "./Table.scss";

export const ConnectOneTableControls = ({
  fullData,
  dataIsLoading,
  totalPages,
  currentPageIndex,
  setCurrentPageIndex,
  filterValues,
  setFilterValues,
  handleFilterSubmit,
  submitButtonDisabled,
  fileName,
  exportData,
}: {
  fullData: any;
  dataIsLoading: boolean;
  totalPages: number;
  currentPageIndex: number;
  setCurrentPageIndex: any;
  filterValues: any;
  setFilterValues: any;
  handleFilterSubmit: any;
  submitButtonDisabled: boolean;
  fileName: string;
  exportData: any;
}) => {
  const isMobile = useIsMobile();
  const [showFilters, setShowFilters] = useState(!isMobile);
  const [showLookupModal, setShowLookupModal] = useState(false);

  const handleFilterChanges = (e: any) => {
    const { name, value } = e.target || e;

    if (!name.includes("date")) {
      setFilterValues({
        ...filterValues,
        // @ts-ignore
        [name]: { ...filterValues[name], value: value },
      });
      return;
    }

    setFilterValues({
      ...filterValues,
      // @ts-ignore
      [name]: { ...filterValues[name], value: value },
    });
  };

  const handleNextPage = () => {
    setCurrentPageIndex(currentPageIndex + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPageIndex(currentPageIndex - 1);
  };

  const handleOnChange = (newPageIndex: number) => {
    setCurrentPageIndex(newPageIndex);
  };

  const showExportButton = !!exportData?.cellHeaders?.length;

  const emptyDataReturned =
    !fullData?.length && currentPageIndex === 1 && !dataIsLoading;

  return (
    <div className="controls-container" data-testid="table-controls">
      <div className="filter-and-controls">
        <ConnectOneFilterBar
          handleChange={handleFilterChanges}
          filterValues={filterValues}
          handleSubmit={handleFilterSubmit}
          dataIsLoading={dataIsLoading}
          submitButtonDisabled={submitButtonDisabled}
          showFilters={showFilters}
          handleOpenMobileMenu={() => setShowFilters(!showFilters)}
          handleSearch={() => setShowLookupModal(true)}
        />

        {showLookupModal && (
          <ConnectOneLookupModal
            label="VIN"
            lookupType="vin"
            handleClose={() => setShowLookupModal(false)}
            handleSetValue={(data: any) => {
              handleFilterChanges(data);
              setShowLookupModal(false);
            }}
          />
        )}

        {showExportButton ? (
          <div
            className={`table-controls ${
              isMobile ? "mobile-table-controls" : null
            }`}
          >
            <div
              className={dataIsLoading ? "disabled-csv-button" : "csv-button"}
            >
              <CSVLink
                filename={fileName}
                headers={exportData.cellHeaders}
                data={exportData.cellData}
                className="csv-text"
              >
                {isMobile ? (
                  <p>Export to CSV</p>
                ) : (
                  <CSVDownloadIcon className={"csv-icon"} />
                )}
              </CSVLink>
            </div>

            <Pagination
              currentPage={currentPageIndex}
              totalPages={totalPages}
              onNext={handleNextPage}
              onPrevious={handlePreviousPage}
              onChange={handleOnChange}
            />
          </div>
        ) : (
          <div
            className={`table-controls ${
              isMobile ? "mobile-table-controls" : null
            }`}
          >
            <p>
              {emptyDataReturned
                ? "No data found. Please try a different search."
                : isMobile
                  ? "To gather report data please use the search menu above."
                  : "To gather report data please use the search controls above."}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
