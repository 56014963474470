import { usStates } from "./states";

export const locationsFilterState = {
  name: {
    label: "Plaza Name",
    value: "",
    name: "name",
  },
  plazaId: {
    label: "Plaza ID",
    value: "",
    name: "plazaId",
  },
  agencyId: {
    label: "Agency",
    value: "",
    name: "agencyId",
    type: "dropdown",
    options: [] as any,
  },
  state: {
    label: "State",
    value: "",
    name: "state",
    type: "dropdown",
    options: usStates,
  },
  locationType: {
    type: "dropdown",
    label: "Location Type",
    value: "",
    name: "locationType",
    options: [] as any,
  },
  status: {
    type: "dropdown",
    label: "Status",
    value: "",
    name: "status",
    options: [] as any,
  },
  verified: {
    type: "dropdown",
    label: "Verified",
    value: "",
    name: "verified",
    options: [
      { label: "", value: "" },
      { label: "Verified", value: "true" },
      { label: "Not Verified", value: "false" },
    ],
  },
};
