import {
  AppSyncClient,
  GraphQLApiType,
  ListGraphqlApisCommand,
} from "@aws-sdk/client-appsync";

import { getCognitoCredentials } from ".";

const getAppSyncClient = async () => {
  const credentials = await getCognitoCredentials();

  const client = new AppSyncClient({
    region: process.env.REACT_APP_region,
    credentials: credentials,
  });

  return client;
};

export const getGraphQLKey = async () => {
  const client = await getAppSyncClient();
  const input = {
    apiType: "GRAPHQL" as GraphQLApiType,
  };
  const command = new ListGraphqlApisCommand(input);
  try {
    const key = await client.send(command);
    return (
      key.graphqlApis.find((api: any) =>
        api.name.includes(process.env.REACT_APP_USER_BRANCH)
      ).uris?.GRAPHQL || ""
    );
  } catch (err) {
    console.log("Get AppSync Data Err: ", err.message);
    return null;
  }
};
