import { useEffect, useState } from "react";
import { addDays, addHours, format } from "date-fns";
import { useSelector } from "react-redux";

import { useIsMobile, useReport } from "../../../hooks";
import { ConnectOneTableControls, ConnectOneTable } from "../../../components";
import { getTollMatchingAPI } from "../../../axios";
import { formatDateToAPI, shapeMatchingTollCSV } from "../../../utils";

import "./TollMatching.scss";

export const TollMatchingReport = () => {
  const isMobile = useIsMobile();
  const [exportData, setExportData] = useState(undefined);
  const agencies = useSelector((state: any) => state.agencies.agenciesData);

  const {
    reportData: gnssTollActivityData,
    setReportData: setGNSSTollActivityData,
    currentPageIndex: gnssTollActivityCurrentPageIndex,
    setCurrentPageIndex: setGNSSTollActivityCurrentPageIndex,
    dataToDisplay: gnssTollActivityDataToDisplay,
    userFriendlyDateInsert,
    totalPages,
    isLoading,
    setIsLoading,
    headers: gnssTollActivityDataHeaders,
    exportFileName,
  } = useReport({ isMobile, reportType: "gnssToll" });

  const {
    reportData: agencyTollActivityData,
    setReportData: setAgencyTollActivityData,
    dataToDisplay: agencyTollActivityDataToDisplay,
    setCurrentPageIndex: setAgencyTollActivityCurrentPageIndex,
    headers: agencyTollActivityDataHeaders,
  } = useReport({ isMobile, reportType: "agencyToll" });

  const [filterValues, setFilterValues] = useState({
    agency: {
      type: "dropdown",
      label: "Agency",
      value: "",
      options: [] as any,
    },
    dateFrom: {
      type: "datetime-local",
      label: "Date From",
      value: `${format(addDays(new Date(), -7), "yyyy-MM-dd")}T00:00`,
    },
    dateTo: {
      type: "datetime-local",
      label: "Date To",
      value: `${format(new Date(), "yyyy-MM-dd")}T${format(
        addHours(new Date(), 1).getTime(),
        "HH:",
      )}00`,
    },
  });

  useEffect(() => {
    setFilterValues((prevFilterValues: any) => ({
      ...prevFilterValues,
      agency: {
        ...prevFilterValues.agency,
        options: [{ label: "", value: "" }, ...agencies],
      },
    }));
  }, [agencies]);

  useEffect(() => {
    setExportData(
      shapeMatchingTollCSV(
        gnssTollActivityDataHeaders,
        agencyTollActivityDataHeaders,
        gnssTollActivityData,
        agencyTollActivityData,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gnssTollActivityData, agencyTollActivityData]);

  const handlePagination = (value: number) => {
    setGNSSTollActivityCurrentPageIndex(value);
    setAgencyTollActivityCurrentPageIndex(value);
  };

  const submitButtonDisabled = Object.values(filterValues).some(
    (filterValue: any) => !filterValue.value,
  );

  const getTableData = async () => {
    setIsLoading(true);
    setGNSSTollActivityCurrentPageIndex(1);
    setAgencyTollActivityCurrentPageIndex(1);
    try {
      const res: any = await getTollMatchingAPI({
        agency: filterValues.agency.value,
        startDate: formatDateToAPI(filterValues.dateFrom.value),
        endDate: formatDateToAPI(filterValues.dateTo.value),
      });

      if (res.length) {
        const gnssTollActivities = res.map((e: any) => e.gnssTollActivities);
        const gnssTollActivitiesData = userFriendlyDateInsert({
          data: gnssTollActivities,
          localDate: "tollDateTimeLocal",
          utcDate: "tollDateTime",
        });

        const agencyTollActivities = res.map(
          (e: any) => e.agencyTollActivities,
        );
        const agencyTollActivitiesData = userFriendlyDateInsert({
          data: agencyTollActivities,
          localDate: "tran_Time_Local",
          utcDate: "tran_Time",
        });

        setGNSSTollActivityData(gnssTollActivitiesData);
        setAgencyTollActivityData(agencyTollActivitiesData);
      }
    } catch (err) {
      setGNSSTollActivityData([]);
      setAgencyTollActivityData([]);
      console.log(err);
    }
    setIsLoading(false);
  };

  return (
    <div className="toll-matching-container">
      <ConnectOneTableControls
        fullData={exportData}
        dataIsLoading={isLoading}
        totalPages={totalPages}
        currentPageIndex={gnssTollActivityCurrentPageIndex}
        setCurrentPageIndex={handlePagination}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        handleFilterSubmit={getTableData}
        submitButtonDisabled={submitButtonDisabled}
        fileName={exportFileName("TOLL_MATCHING_REPORT", filterValues)}
        exportData={exportData}
      />
      {!!gnssTollActivityDataToDisplay.length && (
        <div className="matching-table-container">
          <ConnectOneTable
            data={gnssTollActivityDataToDisplay}
            headers={gnssTollActivityDataHeaders}
            caption="GNSS Toll Activities"
            isMatching={true}
          />
          <ConnectOneTable
            data={agencyTollActivityDataToDisplay}
            headers={agencyTollActivityDataHeaders}
            caption={"Agency Toll Activities"}
            isMatching={true}
          />
        </div>
      )}
    </div>
  );
};
