import { useState } from "react";
import { useSelector } from "react-redux";
import { addDays, addHours, format } from "date-fns";

import { useIsMobile, useReport } from "../../../hooks";
import { ConnectOneTableControls, ConnectOneTable } from "../../../components";
import { getRawRucAPI } from "../../../axios";
import { formatDateToAPI } from "../../../utils";

export const RUCReport = () => {
  const isMobile = useIsMobile();
  const { rawGeofenceData } = useSelector((state: any) => state.geofences);

  const {
    reportData,
    currentPageIndex,
    totalPages,
    dataToDisplay,
    isLoading,
    headers,
    exportData,
    sortAscending,
    sortedValue,
    exportFileName,
    setIsLoading,
    setReportData,
    setCurrentPageIndex,
    userFriendlyDateInsert,
    insertGeofenceName,
    handleSorting,
  } = useReport({ isMobile, reportType: "ruc" });

  const [filterValues, setFilterValues] = useState({
    vin: {
      type: "search",
      label: "Vin",
      value: "",
    },
    dateFrom: {
      type: "datetime-local",
      label: "Date From",
      value: `${format(addDays(new Date(), -7), "yyyy-MM-dd")}T00:00`,
    },
    dateTo: {
      type: "datetime-local",
      label: "Date To",
      value: `${format(new Date(), "yyyy-MM-dd")}T${format(
        addHours(new Date(), 1).getTime(),
        "HH:",
      )}00`,
    },
  });

  const submitButtonDisabled = Object.values(filterValues).some(
    (filterValue: any) => !filterValue.value,
  );

  const getTableData = async () => {
    setIsLoading(true);
    setCurrentPageIndex(1);
    try {
      const res: any = await getRawRucAPI({
        deviceId: filterValues.vin.value,
        startDate: formatDateToAPI(filterValues.dateFrom.value),
        endDate: formatDateToAPI(filterValues.dateTo.value),
      });
      let data = userFriendlyDateInsert({
        data: res,
        localDate: "entryTimeLocal",
        utcDate: "entryTime",
      });
      data = userFriendlyDateInsert({
        data,
        localDate: "exitTimeLocal",
        utcDate: "exitTime",
      });
      data = insertGeofenceName({
        data,
        geofences: rawGeofenceData,
      });
      setReportData(data);
    } catch (err) {
      setReportData([]);
      console.log(err);
    }
    setIsLoading(false);
  };

  return (
    <>
      <ConnectOneTableControls
        fullData={reportData}
        dataIsLoading={isLoading}
        totalPages={totalPages}
        currentPageIndex={currentPageIndex}
        setCurrentPageIndex={setCurrentPageIndex}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        handleFilterSubmit={getTableData}
        submitButtonDisabled={submitButtonDisabled}
        fileName={exportFileName("RUC_REPORT", filterValues)}
        exportData={exportData}
      />
      <ConnectOneTable
        data={dataToDisplay}
        headers={headers}
        caption="RUC"
        sortGridData={handleSorting}
        sortAscending={sortAscending}
        sortedValue={sortedValue}
      />
      <ConnectOneTable data={dataToDisplay} headers={headers} caption="RUC" />
    </>
  );
};
