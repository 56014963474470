import { getMarkerColor } from "../../utils";

export const MarkerIcon = ({
  traceData,
  startPoint,
  endPoint,
}: {
  traceData: any;
  startPoint: boolean;
  endPoint: boolean;
}) => {
  const color = getMarkerColor({
    startPoint,
    endPoint,
    tollingSpeed: traceData.tollingSpeed,
    dataSource: traceData.dataSource,
  });

  return (
    <>
      <svg
        width="25"
        viewBox="0 0 448 512"
        fill={color}
        data-testid="marker-icon"
      >
        <path d="M429.6 92.1c4.9-11.9 2.1-25.6-7-34.7s-22.8-11.9-34.7-7l-352 144c-14.2 5.8-22.2 20.8-19.3 35.8s16.1 25.8 31.4 25.8H224V432c0 15.3 10.8 28.4 25.8 31.4s30-5.1 35.8-19.3l144-352z" />
      </svg>
    </>
  );
};
