import { EditIcon, MapIcon, TableIcon, SettingsIcon } from "../icons";

export const routes = [
  {
    name: "Map",
    path: "/",
    icon: MapIcon,
  },
  {
    name: "Report",
    icon: TableIcon,
    basePath: "/report",
    children: [
      {
        name: "Rates",
        path: "/report/rate",
      },
      {
        name: "RUC",
        path: "/report/ruc",
        adminOnly: true,
      },
      {
        name: "Transactions",
        path: "/report/transaction",
      },
      {
        name: "Toll Matching",
        path: "/report/toll-matching",
        adminOnly: true,
      },
      {
        name: "Vehicle Activity",
        path: "/report/vehicle-activity",
      },
      {
        name: "Vehicle Statuses",
        path: "/report/vehicle-statuses",
      },
      {
        name: "Vehicle Traces",
        path: "/report/vehicle-traces",
      },
    ],
  },
  {
    name: "Admin",
    icon: EditIcon,
    basePath: "/admin",
    adminOnly: true,
    children: [
      {
        name: "Agencies",
        path: "/admin/agencies",
        adminOnly: true,
      },
      {
        name: "Locations",
        path: "/admin/locations",
        adminOnly: true,
      },
      {
        name: "Services",
        path: "/admin/services",
        adminOnly: true,
      },
      {
        name: "Toll Import",
        path: "/admin/tollImport",
        adminOnly: true,
      },
      {
        name: "Vehicles",
        path: "/admin/vehicles",
        adminOnly: true,
      },
    ],
  },
  {
    name: "Settings",
    icon: SettingsIcon,
    basePath: "/settings",
    adminOnly: true,
    children: [
      {
        name: "Manage Users",
        path: "/settings/users",
        adminOnly: true,
      },
    ],
  },
];
