import {
  ConnectOneInput,
  ConnectOneButton,
  ConnectOneDropdown,
  ConnectOneCheckbox,
} from "../..";
import { useIsMobile } from "../../../hooks";

import "./SearchForm.scss";

export const ConnectOneSearchForm = ({
  label,
  searchData,
  handleChange,
  handleSearch,
  handleCheckbox = undefined,
  isLoading = false,
  handleClose = undefined,
}: {
  label: string;
  searchData: any;
  handleChange: any;
  handleSearch: any;
  handleCheckbox?: any;
  isLoading?: boolean;
  handleClose?: any;
}) => {
  const isMobile = useIsMobile();

  const searchEnabled = Object.values(searchData).some(
    (data: any) => data.value !== ""
  );

  return (
    <div
      className={`page-container ${isMobile ? "mobile-page-container" : null}`}
      data-testid={`${label}-search-form`}>
      <div className="search-form-container">
        <div className="search-headers">
          <h1>{`${label} Search`}</h1>
          <div
            className="x-container"
            onClick={handleClose}
            data-testid="search-modal-close-btn">
            <span aria-hidden="true">&times;</span>
          </div>
        </div>
        <div className="search-form-inputs">
          {Object.entries(searchData).map(
            ([key, value]: [key: string, value: any]) => {
              if (value.type === "dropdown") {
                return (
                  <span key={key}>
                    <ConnectOneDropdown
                      handleChange={handleChange}
                      label={value.label}
                      name={value.name}
                      options={value.options}
                      value={value?.value || ""}
                    />
                  </span>
                );
              }

              if (value.type === "checkbox") {
                return (
                  <span key={key}>
                    <ConnectOneCheckbox
                      handleChange={handleCheckbox}
                      label={value.label}
                      name={value.name}
                      value={value.value}
                    />
                  </span>
                );
              }

              return (
                <span key={key}>
                  <ConnectOneInput
                    handleChange={handleChange}
                    label={value.label}
                    type={value.type}
                    name={value.name}
                    placeholder={value.label}
                    value={value.value}
                  />
                </span>
              );
            }
          )}
        </div>
        <div className="button-container">
          <ConnectOneButton
            label="Search"
            handleClick={handleSearch}
            disabled={!searchEnabled}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};
