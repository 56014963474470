import {
  ConnectOneInput,
  ConnectOneButton,
  ConnectOneDropdown,
  ConnectOneSearchInput,
} from "../..";
import { useIsMobile } from "../../../hooks";

import "./FilterBar.scss";

export const ConnectOneFilterBar = ({
  handleChange,
  filterValues,
  handleSubmit,
  dataIsLoading = false,
  submitButtonDisabled = true,
  showFilters = true,
  handleOpenMobileMenu = () => {},
  handleSearch = () => {},
}: {
  handleChange: any;
  filterValues: any;
  handleSubmit: any;
  dataIsLoading?: boolean;
  submitButtonDisabled?: boolean;
  showFilters?: boolean;
  handleOpenMobileMenu?: any;
  handleSearch?: any;
}) => {
  const isMobile = useIsMobile();

  return (
    <>
      {isMobile && (
        <div
          className="minimized-mobile-filter-container filter-container"
          data-testid="minimized-mobile-filter-bar"
          onClick={() => handleOpenMobileMenu("filterBar")}
        >
          <p data-testid="open-filter-button">Search</p>
        </div>
      )}

      {showFilters && (
        <div
          className={`${
            isMobile ? "mobile-filter-container" : null
          } filter-container`}
          data-testid={`${isMobile ? "mobile-filter-bar" : "filter-bar"}`}
        >
          <div className="filter-with-button-container">
            <div className="filter-inputs-container">
              {Object.entries(filterValues).map(
                ([key, value]: [key: string, value: any], index) => {
                  if (value.type === "dropdown") {
                    return (
                      <span key={`dropdown-input-${index}`}>
                        <ConnectOneDropdown
                          handleChange={handleChange}
                          label={value.label}
                          name={key}
                          placeholder={value.label}
                          value={value.value}
                          options={value.options}
                        />
                      </span>
                    );
                  }

                  if (value.type === "search") {
                    return (
                      <span key={`seach-input-${index}`}>
                        <ConnectOneSearchInput
                          name={key}
                          label={value.label}
                          value={value.value}
                          handleChange={handleChange}
                          handleSearch={handleSearch}
                          labelHidden={value.labelHidden}
                        />
                      </span>
                    );
                  }

                  return (
                    <span key={`text-input-${index}`}>
                      <ConnectOneInput
                        handleChange={handleChange}
                        label={value.label}
                        type={value.type}
                        name={key}
                        placeholder={value.label}
                        value={value.value}
                      />
                    </span>
                  );
                }
              )}
            </div>

            <div className="submit-button">
              <ConnectOneButton
                label="Search"
                disabled={submitButtonDisabled}
                handleClick={() => {
                  if (isMobile) handleOpenMobileMenu("filterBar");
                  handleSubmit();
                }}
                isLoading={dataIsLoading}
              />
            </div>
          </div>
          {isMobile && (
            <p
              onClick={() => handleOpenMobileMenu("filterBar")}
              className="mobile-close-button"
              data-testid="mobile-close-button"
            >
              CLOSE
            </p>
          )}
        </div>
      )}
    </>
  );
};
