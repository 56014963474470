import { usStates } from "./states";

export const vehicleFilterState = {
  nickName: {
    label: "Nickname",
    value: "",
    name: "nickName",
  },
  vin: {
    label: "Vin",
    value: "",
    name: "vin",
    required: true,
  },
  licensePlate: {
    label: "Plate",
    value: "",
    name: "licensePlate",
    required: true,
  },
  licensePlateState: {
    label: "Plate State",
    value: "",
    name: "licensePlateState",
    type: "dropdown",
    options: usStates,
    required: true,
  },
  plateType: {
    label: "Plate Type",
    value: "",
    name: "plateType",
  },
  make: {
    label: "Make",
    value: "",
    name: "make",
  },
  model: {
    label: "Model",
    value: "",
    name: "model",
  },
  year: {
    type: "number",
    label: "Year",
    value: "",
    name: "year",
  },
  provider: {
    label: "Provider",
    value: "",
    name: "provider",
    required: true,
  },
  class: {
    type: "dropdown",
    label: "Class",
    value: "",
    name: "class",
    options: [] as any,
    required: true,
  },
  axles: {
    type: "number",
    label: "Axles",
    value: "",
    name: "axles",
  },
  tires: {
    type: "number",
    label: "Tires",
    value: "",
    name: "tires",
  },
  fuelType: {
    type: "dropdown",
    label: "Fuel Type",
    value: "",
    name: "fuelType",
    options: [] as any,
  },
  deviceType: {
    label: "Vehicle Device Type",
    value: "",
    name: "deviceType",
    readOnly: true,
  },
  tollTransponderId: {
    label: "Toll Transponder ID",
    value: "",
    name: "tollTransponderId",
  },
  fuelChargeId: {
    label: "Fuel / Charge Card ID",
    value: "",
    name: "fuelChargeId",
  },
  obd2ID: {
    label: "Telematics ID",
    value: "",
    name: "obd2ID",
  },
  equity: {
    type: "dropdown",
    label: "Equity",
    value: "",
    name: "equity",
    options: [
      { label: "", value: "" },
      { label: "Equity", value: true },
      { label: "No Equity", value: false },
    ],
  },
  status: {
    type: "dropdown",
    label: "Status",
    value: "",
    name: "status",
    options: [] as any,
    required: true,
  },
};

export const vehicleEditState = {
  ...vehicleFilterState,
  equity: {
    type: "dropdown",
    label: "Equity",
    value: "false",
    name: "equity",
    options: [
      { label: "Equity", value: true },
      { label: "No Equity", value: false },
    ],
  },
  communicationPreferences: {
    receiveInfotainmentMessages: {
      type: "checkbox",
      label: "Receive Messages",
      value: true,
      name: "receiveInfotainmentMessages",
    },
  },
  _id: {},
};

export const vinLookupFilterState = {
  ...vehicleFilterState,
  vin: {
    label: "Last 4 of VIN",
    value: "",
    name: "vin",
  },
};
