import { useMemo } from "react";
import { useSelector } from "react-redux";

import {
  ConnectOneButton,
  ConnectOneCheckbox,
  ConnectOneDropdown,
  ConnectOneInput,
  ConnectOneTextBox,
} from "../..";

import "./GeofenceModal.scss";

export const GeofenceDetailsEditForm = ({
  geofence,
  handlePropertyChange,
  isLoading = false,
  handleSubmitChange = undefined,
  isCreating = false,
}: {
  geofence: any;
  handlePropertyChange: any;
  isLoading?: boolean;
  handleSubmitChange?: any;
  isCreating?: boolean;
}) => {
  const agencies = useSelector((state: any) => state.agencies.agenciesData);
  const locationStatuses = useSelector(
    (state: any) => state.locationStatuses.locationStatusesData
  );
  const locationHierarchy = useSelector(
    (state: any) => state.locationHierarchy.locationHierarchyData
  );

  let {
    name,
    locationType,
    agencyId,
    plazaId,
    eventActions,
    notes,
    verified,
    roadName,
    status,
    locationSubType,
    course,
  } = geofence?.properties?.value || { ...geofence?.properties };

  const selectedAgency = useMemo(() => {
    if (agencies.length) {
      return agencies?.find((a: any) => a._id === agencyId);
    }
  }, [agencies, agencyId]);

  // Set the status to inactive if isCreating with an inactive agency
  if (isCreating) {
    if (selectedAgency && selectedAgency.statusString !== "Active") {
      // Find locationStatus of Inactive
      const inactiveStatusId = locationStatuses.find(
        (status: any) => status.name === "Inactive"
      )?._id;
      status = inactiveStatusId;
      if (geofence?.properties?.value?.hasOwnProperty("status")) {
        geofence.properties.value.status = inactiveStatusId;
      } else if (geofence?.properties?.hasOwnProperty("status")) {
        geofence.properties.status = inactiveStatusId;
      }
    }
  }

  // Handle validation for the submit button
  const requiredValues = ["status", "name", "agencyId", "locationType"];
  const submitEnabled = requiredValues?.every((value) => {
    return (
      geofence?.properties[value] && geofence?.properties[value]?.length > 0
    );
  });

  const locationTypes = useMemo(() => {
    if (locationHierarchy?.get) {
      const locationTypes: { label: string; value: string }[] = [];

      locationHierarchy.forEach((locationType: any) => {
        locationTypes.push({
          label: locationType.locationType,
          value: locationType.locationType,
        });
      });

      return locationTypes;
    }
  }, [locationHierarchy]);

  const locationSubTypes = useMemo(() => {
    if (locationType && locationHierarchy?.get) {
      const { subTypeEventActions } = locationHierarchy?.get(`${locationType}`);

      const locationSubTypes: { label: string; value: string }[] = [];

      if (subTypeEventActions) {
        subTypeEventActions.forEach((subType: any) => {
          if (subType.locationSubType) {
            locationSubTypes.push({
              label: subType.locationSubTypeName,
              value: subType.locationSubType,
            });
          }
        });
      }

      return locationSubTypes;
    }
  }, [locationHierarchy, locationType]);

  const locationActions = useMemo(() => {
    if (locationType && locationHierarchy?.get) {
      const { subTypeEventActions } = locationHierarchy?.get(`${locationType}`);

      const actionsByLocationType =
        subTypeEventActions?.find(
          (e: any) => e.locationSubType === locationSubType
        )?.locationEventActions || subTypeEventActions?.locationEventActions;

      if (actionsByLocationType) {
        const entryActions = actionsByLocationType
          ?.find((e: any) => e.event === "ENTER")
          ?.actions?.map((e: any) => {
            return { label: e.name, value: e.action };
          });
        const exitActions = actionsByLocationType
          ?.find((e: any) => e.event === "EXIT")
          ?.actions?.map((e: any) => {
            return { label: e.name, value: e.action };
          });

        return {
          ENTRY: entryActions
            ? [{ label: "None", value: "None" }].concat(entryActions)
            : undefined,
          EXIT: exitActions
            ? [{ label: "None", value: "None" }].concat(exitActions)
            : undefined,
        };
      }
    }
  }, [locationHierarchy, locationSubType, locationType]);

  return (
    <div className="edit-geofence-modal" data-testid="edit-geofence-modal">
      <div className="property-inputs-container">
        <div className="location-details">
          <ConnectOneInput
            handleChange={handlePropertyChange}
            label="Plaza Name"
            type="text"
            name="name"
            value={name || ""}
            required={true}
            placeholder="Plaza Name"
          />
          <ConnectOneInput
            handleChange={handlePropertyChange}
            label="Plaza ID"
            type="text"
            name="plazaId"
            value={plazaId}
            placeholder="Plaza ID"
          />
          <ConnectOneInput
            handleChange={handlePropertyChange}
            label="Road Name"
            type="text"
            name="roadName"
            value={roadName || ""}
            placeholder="Road Name"
          />
        </div>

        <div className="agency-details">
          <ConnectOneDropdown
            handleChange={handlePropertyChange}
            label={
              <>
                Agency
                {selectedAgency && (
                  <span>
                    {" - "}
                    <span
                      style={{
                        color:
                          selectedAgency.statusString === "Active"
                            ? "green"
                            : "red",
                      }}
                    >
                      {selectedAgency.statusString}
                    </span>
                  </span>
                )}
              </>
            }
            name="agencyId"
            value={agencyId}
            options={agencies}
            required={true}
          />
        </div>

        <div className="location-type">
          <ConnectOneDropdown
            handleChange={handlePropertyChange}
            label="Location Type"
            name="locationType"
            value={locationType}
            options={locationTypes}
            required={true}
          />
          {!!locationSubTypes?.length && (
            <ConnectOneDropdown
              handleChange={handlePropertyChange}
              label="Location Subtype"
              name="locationSubType"
              value={locationSubType}
              options={locationSubTypes}
              required={true}
            />
          )}
          <ConnectOneInput
            handleChange={handlePropertyChange}
            label="Roadway Direction (Degrees)"
            type="number"
            name="course"
            value={course}
            placeholder="None"
            max="359.99"
          />
        </div>

        {locationActions && (
          <div className="location-actions">
            {locationActions["ENTRY"] && (
              <ConnectOneDropdown
                handleChange={handlePropertyChange}
                label="Enter Action"
                name="ENTER"
                value={
                  eventActions?.find((e: any) => e.event === "ENTER")?.action ||
                  "None"
                }
                options={locationActions["ENTRY"] || undefined}
              />
            )}
            {locationActions["EXIT"] && (
              <ConnectOneDropdown
                handleChange={handlePropertyChange}
                label="Exit Action"
                name="EXIT"
                value={
                  eventActions?.find((e: any) => e.event === "EXIT")?.action ||
                  "None"
                }
                options={locationActions["EXIT"] || undefined}
              />
            )}
          </div>
        )}

        <div className="location-extras">
          <div className="location-status">
            <ConnectOneDropdown
              handleChange={handlePropertyChange}
              label="Status"
              name="status"
              value={status}
              options={locationStatuses}
              required={true}
              disabled={
                isCreating && !selectedAgency
                  ? false
                  : selectedAgency?.statusString !== "Active"
              }
            />
            {!isCreating && (
              <ConnectOneCheckbox
                label="Verified"
                name="verified"
                value={verified}
                handleChange={handlePropertyChange}
                labelPosition="end"
              />
            )}
          </div>
          {!isCreating && (
            <div className="notes">
              <ConnectOneTextBox
                handleChange={handlePropertyChange}
                type="text-area"
                label="Notes"
                name="notes"
                value={notes}
                placeholder="Add Additional Notes Here..."
              />
            </div>
          )}
        </div>
      </div>

      {!isCreating && !!handleSubmitChange && (
        <div className="button-container">
          <ConnectOneButton
            label="Save Changes"
            handleClick={handleSubmitChange}
            isLoading={isLoading}
            disabled={!submitEnabled}
          />
        </div>
      )}
    </div>
  );
};
