import { Button } from "@aws-amplify/ui-react";

import { useIsMobile } from "../../../hooks";

import "./Button.scss";

export const ConnectOneButton = ({
  label,
  handleClick,
  disabled = false,
  isLoading = false,
  color = "",
}: {
  label: string;
  handleClick: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  color?: string;
}) => {
  const isMobile = useIsMobile();

  return (
    <div className={`${isMobile ? "mobile-button" : null} button `}>
      <Button
        onClick={handleClick}
        data-testid={disabled ? `${label}-disabled-button` : `${label}-button`}
        className={disabled ? `disabled-button` : null}
        disabled={disabled}
        isFullWidth={isMobile}
        isLoading={isLoading}
        style={{
          color: disabled ? null : color,
        }}>
        {label}
      </Button>
    </div>
  );
};
