import axios from "axios";

import { refreshCognitoToken, getUserAccessToken } from "../../utils";

export const getTollImportMetadata = async () => {
  try {
    const accessToken = await getUserAccessToken();
    await refreshCognitoToken();
    const response = await axios({
      url: `${process.env.REACT_APP_API_URL}/tollImportMetadata`,
      method: "GET",
      headers: {
        Authorization: accessToken,
      },
    });
    return response.data;
  } catch (err) {
    console.log("Get Toll Import Metadata Err: ", err.message);
  }
};
