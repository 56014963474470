import { usStates } from "./states";

export const agencyEditState = {
  name: {
    label: "Plaza Name",
    value: "",
    name: "name",
  },
  agencyId: {
    label: "Agency",
    value: "",
    name: "agencyId",
    type: "dropdown",
    options: [] as any,
  },
  state: {
    label: "State",
    value: "",
    name: "state",
    type: "dropdown",
    options: usStates,
  },
  locationType: {
    type: "dropdown",
    label: "Location Type",
    value: "",
    name: "locationType",
    options: [] as any,
  },
  verified: {
    type: "checkbox",
    label: "Verified",
    value: "",
    name: "verified",
  },
  services: {
    label: "Services",
    name: "services",
    type: "checkboxes-string",
    value: [] as string[],
    options: [] as any,
  },
  status: {
    label: "Status",
    name: "status",
    type: "dropdown",
    value: [] as string[],
    options: [] as any,
  },
  _id: {},
};
