// Custom hook to handle functionality and state used within the map page
import { useEffect, useRef, useState } from "react";
import { API } from "aws-amplify";
import { addHours, format, isSameDay } from "date-fns";
import { useSelector } from "react-redux";

import { getPointMesaurements, shapeAndFilterTraceData } from "../utils";
import { tracesByVin } from "../graphql/queries";
import { useIsMobile } from "./useIsMobile";
import { isEmpty } from "lodash";

export const useMap = ({
  map,
  agencies,
  locationData,
}: {
  map: any;
  agencies: any;
  locationData: any;
}) => {
  const isMobile = useIsMobile();

  const [isDataLoading, setIsDataLoading] = useState(false);
  const [pollData, setPollData] = useState(false);
  const [measurementData, setMeasurementData] = useState<any>({});
  const [clearRulerLine, setClearRulerLine] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const [showFilters, setShowFilters] = useState(!isMobile);
  const [showLookupModal, setShowLookupModal] = useState(false);
  const [showRulerModal, setShowRulerModal] = useState(false);
  const [dynamicFillColorsByType, setDynamicFillColorsByType] = useState([]);
  const [dynamicFillColorsByStatus, setDynamicFillColorsByStatus] = useState(
    [],
  );

  const [tracePoints, setTracePoints] = useState<any[]>([]);

  const locationStatuses = useSelector(
    (state: any) => state.locationStatuses.locationStatusesData,
  );

  // Map Styling
  const [mapStyle, setMapStyle] = useState({
    style: "GNSS_Tolling_UI-Hybrid",
    name: "Hybrid",
  });

  // Filters
  const [filterValues, setFilterValues] = useState({
    vin: {
      type: "search",
      label: "VIN",
      value: "",
      labelHidden: !isMobile,
    },
    dateFrom: {
      type: "datetime-local",
      value: `${format(new Date(), "yyyy-MM-dd")}T00:00`,
      label: `${isMobile ? "Date / Time Start" : ""}`,
    },
    dateTo: {
      type: "time",
      value: `${format(addHours(new Date(), 1).getTime(), "HH:")}00`,
      label: `${isMobile ? "Time End" : ""}`,
    },
  });

  // Are all filter inputs valid
  const submitButtonDisabled = Object.values(filterValues).some(
    (filterValue) =>
      filterValue.value === undefined || filterValue.value === "",
  );

  // Handle Map Style
  const handleMapStyleSwitch = (style: string) => {
    if (style === "Hybrid") {
      setMapStyle({ style: "GNSS_Tolling_UI-Hybrid", name: "Hybrid" });
    } else if (style === "Basic") {
      setMapStyle({ style: "GNSS_Tolling_UI-Explore", name: "Basic" });
    } else if (style === "Satellite") {
      setMapStyle({ style: "GNSS_Tolling_UI-Imagery", name: "Satellite" });
    }
  };

  useEffect(() => {
    const fillColors: string[] = [];

    if (isEmpty(locationData)) return;

    // Loop over each key of locationData
    for (let [key, value] of locationData) {
      const { geofenceFillColor } = value;
      fillColors.push(key, geofenceFillColor);
    }

    setDynamicFillColorsByType(fillColors);
  }, [locationData]);

  useEffect(() => {
    const fillColors: string[] = [];

    if (isEmpty(locationStatuses)) return;

    // Loop over each key of locationData
    for (let locationStatus of locationStatuses) {
      if (locationStatus.name === "Active") continue;
      fillColors.push(locationStatus._id, "#222222");
    }

    setDynamicFillColorsByStatus(fillColors);
  }, [locationStatuses]);

  // Handle Filters
  const handleFilterChanges = (e: any) => {
    setPollData(false);
    const { name, value } = e.target || e;

    setFilterValues({
      ...filterValues,
      // @ts-ignore
      [name]: { ...filterValues[name], value: value },
    });

    return;
  };

  const useInterval = (callback: any, delay: number) => {
    const savedCallback = useRef();

    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
      function tick() {
        if (isSameDay(new Date(), new Date(filterValues.dateFrom.value))) {
          // @ts-ignore
          if (pollData) savedCallback.current();
        }
      }
      if (delay !== null) {
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [delay, pollData]);
  };

  const handleFilterSubmit = async () => {
    try {
      setIsDataLoading(true);
      const tracePointData = await getDataFromAppSync();

      if (tracePointData.length) {
        map.current.flyTo(
          {
            center: [tracePointData[0].longitude, tracePointData[0].latitude],
            zoom: 12,
          },
          [],
        );
        setPollData(true);
      }
    } catch (err) {
      console.log("Get Data from AppSync Error: ", err);
    }
    setIsDataLoading(false);
  };

  const getDataFromAppSync = async () => {
    const [hours, minutes] = filterValues.dateTo.value.split(":");

    const response: any = await API.graphql({
      query: tracesByVin,
      variables: {
        vin: filterValues.vin.value,
        dateFrom: new Date(filterValues.dateFrom.value).valueOf(),
        dateTo: new Date(filterValues.dateFrom.value).setHours(
          parseInt(hours),
          parseInt(minutes),
        ),
      },
    });
    const tracePointData = shapeAndFilterTraceData(response.data.tracesByVin);
    setTracePoints(tracePointData);
    return tracePointData;
  };

  const handleOpenMobileMenu = (toggledType: string) => {
    if (toggledType === "filterBar") {
      setShowControls(false);
      setShowFilters(!showFilters);
    } else if (toggledType === "controls") {
      if (isMobile) setShowFilters(false);
      setShowControls(!showControls);
    }
  };

  // Handle Map Ruler
  const handleMapMeasure = ({
    startPoint,
    endPoint,
  }: {
    startPoint: number[];
    endPoint: number[];
  }) => {
    setClearRulerLine(false);
    setShowRulerModal(true);
    setMeasurementData(getPointMesaurements({ startPoint, endPoint }));
  };

  const handleMapMeasureEnd = () => {
    setClearRulerLine(true);
    setShowRulerModal(false);
    setMeasurementData({});
  };

  return {
    mapStyle,
    isDataLoading,
    filterValues,
    tracePoints,
    submitButtonDisabled,
    showControls,
    showFilters,
    showLookupModal,
    showRulerModal,
    dynamicFillColorsByType,
    dynamicFillColorsByStatus,
    measurementData,
    clearRulerLine,
    setShowLookupModal,
    getDataFromAppSync,
    useInterval,
    setMapStyle,
    handleFilterSubmit,
    handleFilterChanges,
    handleMapStyleSwitch,
    handleOpenMobileMenu,
    handleMapMeasure,
    handleMapMeasureEnd,
  };
};
