import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useAdmin } from "../../../hooks";
import { putAgency } from "../../../redux";
import { shapeCSVData } from "../../../utils";
import { AgenciesEditForm } from "./AgenciesEditForm";
import { agencyHeaders, agencyEditState } from "../../../assets";
import {
  ConnectOneClickableTable,
  ConnectOneLoader,
} from "../../../components";

import "./Agencies.scss";

export const AdminControlAgencies = ({ user }: { user?: any }) => {
  const [exportData, setExportData] = useState({
    cellHeaders: null,
    cellData: null,
  });

  const {
    agenciesData: agencies,
    agencyServicesData,
    agencyStatusesData,
    isLoading: agenciesLoading,
    statusesLoading,
  } = useSelector((state: any) => state.agencies);

  useEffect(() => {
    if (agencyServicesData?.length) {
      agencyEditState.services.options = agencyServicesData;
    }
    if (agencyStatusesData?.length) {
      agencyEditState.status.options = agencyStatusesData;
    }
  }, [agencyServicesData, agencyStatusesData]);

  const {
    isEditing,
    responseData,
    editData,
    isViewing,
    sortedValue,
    sortAscending,
    isCreating,
    handleAdd,
    setIsViewing,
    setResponseData,
    handleRowSelection,
    handleSorting,
    handleEditFormSubmit,
    handleCheckboxChange,
    handleEditInputChange,
    handleGoBack,
  } = useAdmin({
    editState: agencyEditState,
    handleSubmit: putAgency,
    user: user,
  });

  useEffect(() => {
    if (agencies.length && !statusesLoading) {
      setResponseData(
        agencies.map((agency: any) => {
          return {
            ...agency,
            statusName:
              agencyStatusesData?.find(
                (status: any) => status._id === agency?.status
              )?.name || "",
          };
        })
      );
      setIsViewing(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencies, agencyStatusesData, statusesLoading]);

  useEffect(() => {
    const { cellHeaders, cellData } = shapeCSVData(agencyHeaders, responseData);
    setExportData({ cellHeaders, cellData });
  }, [responseData]);

  return (
    <div className="agency-page-container">
      {agenciesLoading && statusesLoading && <ConnectOneLoader />}
      {isViewing && (
        <ConnectOneClickableTable
          data={responseData}
          label={"Agencies"}
          headers={agencyHeaders}
          sortGridData={handleSorting}
          exportData={exportData}
          handleRowSelection={handleRowSelection}
          autoCompleteSearch={true}
          actionButtonLabel={"Add New Agency"}
          handleActionButton={handleAdd}
          sortedValue={sortedValue}
          sortAscending={sortAscending}
        />
      )}

      {isEditing && (
        <AgenciesEditForm
          data={editData}
          onClose={handleGoBack}
          handleSubmit={handleEditFormSubmit}
          handleCheckbox={handleCheckboxChange}
          handleChange={handleEditInputChange}
          isCreating={isCreating}
        />
      )}
    </div>
  );
};
